import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { StructureConstants } from '../../../assets/structure-config';
import { TENANT_CONFIG } from '../../../environments/config';
import { environment } from '../../../environments/environment';
import { MODULES } from '../../../environments/module.config';
import { VERSION } from '../../../environments/version';
import { ConfirmationDialogData } from '../../models/confirmation-dialog-data.dto';
import { MenuItem } from '../../models/cube';
import { TimeWindow } from '../../models/time-window.model';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { FileUploadError } from '../../shared/configs/file-upload-error.config';
import { StructureType } from '../../shared/configs/structure-type.config';
import { InspectionService } from '../api/inspection/inspection.service';
import { IdbService, WhereConditions } from '../storage/idb.service';
import { StorageService, STORAGE_KEYS } from '../storage/storage.service';
import { UserService } from '../user/user.service';

import { TranslateService } from '@ngx-translate/core';
import { Image as ImageJs } from 'image-js';
import { KeycloakService } from 'keycloak-angular';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import {
  BehaviorSubject,
  fromEvent as observableFromEvent,
  interval,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { filter, take } from 'rxjs/operators';
import UTIF from 'utif';

export const enum MessageType {
  Info,
  Error,
}

@Injectable({
  providedIn: 'root',
})
export class CubeService {
  changeCubeMenu$: Subject<any> = new Subject();
  colorSeed = Date.now();
  modules: {};
  networkStatus: boolean = false;
  offlineStatus: Observable<Event>;
  onlineStatus: Observable<Event>;
  sidenavSbj: Subject<any> = new Subject();
  ws: Subject<any>;

  private _refreshing_time = 5000; // in milliseconds
  private _timeWindow: TimeWindow = new TimeWindow();
  private _timeWindowSbj = new Subject<TimeWindow>();
  private _tokenSbj = new Subject<string>();
  private isOnline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private now: moment.Moment;
  private tsSbj = new Subject<moment.Moment>();
  private version = VERSION;

  constructor(
    private idbService: IdbService,
    private inspectionService: InspectionService,
    private keycloakService: KeycloakService,
    private logger: NGXLogger,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private storageService: StorageService,
    private translateService: TranslateService,
    private userService: UserService
  ) {
    if (environment.refreshing_time) {
      const rt = +environment.refreshing_time.slice(0, -1);
      switch (
        environment.refreshing_time.charAt(
          environment.refreshing_time.length - 1
        )
      ) {
        case 'm':
          this._refreshing_time = rt * 60000;
          break;
        case 's':
        default:
          this._refreshing_time = rt * 1000;
          break;
      }
      this.onlineStatus = observableFromEvent(window, 'online');
      this.offlineStatus = observableFromEvent(window, 'offline');
    }

    interval(this._refreshing_time).subscribe(
      (x) => this.updateTime(),
      (e) => this.handleError<any>('CubeService interval: ' + e.message)
    );
    interval(this._refreshing_time).subscribe(
      (x) => this.setTimeSlider(),
      (e) => this.handleError<any>('CubeService interval: ' + e.message)
    );
    interval(environment.auth.refreshing_time).subscribe(
      (x) => this.refreshSession(),
      (e) => this.handleError<any>('CubeService interval: ' + e.message)
    );
    this.setTimeSlider();
    this.updateTime();
  }

  cleanCache(): void {
    this.storageService.deleteFromStorage(STORAGE_KEYS.activeRole);
    this.storageService.deleteFromStorage(STORAGE_KEYS.currentTenant);
  }

  configureLocalStorageVariables(token, instance): void {
    if (
      (localStorage.getItem(STORAGE_KEYS.version) &&
        localStorage.getItem(STORAGE_KEYS.version) !== this.getVersion()) ||
      (localStorage.getItem(STORAGE_KEYS.sub) &&
        localStorage.getItem(STORAGE_KEYS.sub) !== instance.idTokenParsed.sub)
    ) {
      localStorage.clear();
      indexedDB.deleteDatabase('ngStorage');
      localStorage.setItem(
        STORAGE_KEYS.currentTenant,
        this.userService.userTenantsList[0]
      );
    }

    localStorage.setItem(STORAGE_KEYS.token, token);
    localStorage.setItem(STORAGE_KEYS.version, this.getVersion());
    localStorage.setItem(STORAGE_KEYS.sub, instance.idTokenParsed.sub);
  }

  confirm(
    dialogData?: ConfirmationDialogData,
    width: string = '485px'
  ): Observable<boolean> {
    const matDialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width,
      data: dialogData,
    });

    return matDialogRef.afterClosed().pipe(take(1));
  }

  convertTiffToPNG(file: File): Promise<File> {
    const fileCopy = file;

    return new Promise<File>((resolve) => {
      const url = window.URL.createObjectURL(fileCopy);

      ImageJs.load(url)
        .then((image) => {
          const canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;

          const context = canvas.getContext('2d');
          context.drawImage(image.getCanvas(), 0, 0);

          canvas.toBlob((blob) => {
            const pngFileName = fileCopy.name.replace(/\.tiff?$/i, '.png');
            const pngFile = new File([blob], pngFileName, {
              type: 'image/png',
            });

            // If conversion is successful then returning converted file
            resolve(pngFile);
          }, 'image/png');
        })
        .catch((error) => {
          console.log('tiff error', error);

          // If error occurs during conversion then returning original file
          resolve(file);
        });
    });
  }

  async downloadTiffWithMessage(file: File): Promise<void> {
    this.confirm({
      message:
        'Preview of this Tiff image is unavailable. Would you like to download it instead?',
      confirmButtonText: 'Download',
      title: 'Info',
    })
      .pipe(filter((isConfirm) => isConfirm))
      .subscribe(() => {
        const url = URL.createObjectURL(file);
        window.open(url);
        URL.revokeObjectURL(url);
      });
  }

  formatSize(bytes: number, decimalPoints: number = 3): string {
    if (bytes === 0) return '0 B';

    const k = 1000;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(decimalPoints))} ${sizes[i]}`;
  }

  generateUniqueId(): string {
    return String(Date.now().toString(32) + Math.random().toString(16)).replace(
      /\./g,
      ''
    );
  }

  getDeepCopy(item: any): any {
    return JSON.parse(JSON.stringify(item));
  }

  getEncodedUrl(url: string, token: string): string {
    const encodedUrl = new URL(`${url}?${token}`);

    return encodedUrl.toString();
  }

  getFileName(fileUrl: string): string {
    const fileName = fileUrl.includes(StructureConstants.idbFile)
      ? fileUrl.split(StructureConstants.idbFile).pop()
      : fileUrl.split('/').pop();
    const hasUuid = fileName.startsWith('uuid_') && fileName.length > 41;

    return hasUuid ? fileName.slice(41) : fileName;
  }

  getMinAndMaxDate(): { minDate: Date; maxDate: Date } {
    const currentYear = new Date().getFullYear();
    const minDate = new Date(currentYear - environment.year_filter_limit, 0, 1);
    const maxDate = new Date(currentYear, 11, 31);

    return {
      minDate,
      maxDate,
    };
  }

  getOnlineStatus() {
    return this.isOnline$.value;
  }

  getPropByPathList(obj, pathList: any[]): any {
    return pathList.reduce((value, path) => value?.[path], obj);
  }

  getTimeWindow(): TimeWindow {
    return this._timeWindow;
  }

  getTS(): moment.Moment {
    return this.now;
  }

  getTSObservable(): Observable<moment.Moment> {
    return this.tsSbj.asObservable();
  }

  getUserMenu(): MenuItem[] {
    const isOnline = this.getOnlineStatus();
    const userMenuItems: MenuItem[] = [];
    this.modules = MODULES;

    // Checking if module has menu and if user has roles required for the module
    Object.keys(this.modules).forEach((moduleName: string) => {
      const module = { ...this.modules[moduleName] };

      // Checking if module has menu and if user has roles required for the module
      if (module.menu && this.userService.hasTenantModules(module.roles)) {
        // Checking if module has sub menu
        if (module.menu.sub) {
          // Filtering sub menu items based on user roles required for the pages
          module.menu.sub = module.menu.sub.filter((subMenuItem) => {
            const subModule = this.modules[subMenuItem.key];
            if (!subModule) return false;

            // If offline & module not available offline
            if (!isOnline && !subModule.isAvailableOffline) return false;

            return this.userService.hasTenantModules(subModule.roles);
          });
        }

        if (isOnline) {
          userMenuItems.push(module.menu);
        } else if (module.isAvailableOffline) {
          userMenuItems.push(module.menu);
        }
      }
    });

    // Adding external pages configured in TENANT_CONFIG settings
    TENANT_CONFIG.settings.externalPages?.forEach((page: any) => {
      if (this.userService.hasTenantModules(page.roles)) {
        userMenuItems.push({
          external: true,
          link: page.link,
          mat_icon: page.icon,
          name: page.name,
          open: false,
        });
      }
    });

    return userMenuItems;
  }

  getVersion(): string {
    return this.version.version + '-' + this.version.hash;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error.message);

      // TODO: better job of transforming error for user consumption
      this.logger.error(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  isAlreadyUploadedFile(filePath: string): boolean {
    if (!filePath.length) return false;

    const fileName = filePath.substring(filePath.lastIndexOf('/') + 1);
    const hasUuid = fileName.startsWith('uuid_') && fileName.length > 41;

    return hasUuid;
  }

  isFileTypeAccepted(
    filesList: FileList,
    acceptedFileTypes: string[]
  ): boolean {
    for (const file of filesList) {
      if (file.type && !acceptedFileTypes.includes(file.type)) {
        this.showMessage(
          MessageType.Error,
          filesList.length === 1
            ? 'file_upload_error_message.file_upload_invalid_type'
            : 'file_upload_error_message.file_upload_invalid_type_multiple',
          {
            fileTypes: acceptedFileTypes.join(', '),
          }
        );

        return false;
      }
    }

    return true;
  }

  async isImageDimensionAccepted(file: File): Promise<boolean> {
    try {
      if (
        !environment.file_upload.accepted_file_types.images.includes(file.type)
      )
        return false;

      // Calculating image dimensions
      let imageHeight: number;
      let imageWidth: number;
      try {
        // Getting dimensions using Image object
        const { width, height } = await this.getImageDimensions(file);
        imageWidth = width;
        imageHeight = height;
      } catch (error) {
        if (this.isTiffFile(file)) {
          // If error occurs while getting dimensions using Image object and file is TIFF, then getting dimensions using UTIF library
          const { width, height } = await this.getTiffImageDimensions(file);
          imageWidth = width;
          imageHeight = height;
        }
      }

      if ((!imageHeight || !imageWidth) && this.isTiffFile(file)) {
        // If image dimensions are not found using Image object and file is TIFF, then getting dimensions using UTIF library
        const { width, height } = await this.getTiffImageDimensions(file);
        imageWidth = width;
        imageHeight = height;
      }

      if (!imageHeight || !imageWidth) {
        throw new Error(FileUploadError.UnableToGetImageDimensions);
      }

      const maxImageWidthHeight = environment.file_upload.maxImageWidthHeight; // maximum supported dimension for jpg images

      return (
        imageHeight &&
        imageWidth &&
        (imageHeight > maxImageWidthHeight || imageWidth > maxImageWidthHeight)
      );
    } catch (err) {
      throw new Error(FileUploadError.UnableToGetImageDimensions);
    }
  }

  isTiffFile(file: File): boolean {
    return ['image/tiff', 'image/tif'].includes(file.type);
  }

  openFileInNewTab(file: File): void {
    // If file is TIFF then downloading instead of opening
    if (this.isTiffFile(file)) {
      this.downloadTiffWithMessage(file);

      return;
    }

    // Opening file in new tab
    const url = window.URL.createObjectURL(file);
    window.open(url);
    window.URL.revokeObjectURL(url);
  }

  async openFilePreview(
    fotoName: string,
    token: string,
    whereConditions: WhereConditions,
    structureType?: StructureType
  ) {
    // If file is already uploaded to DB
    const isAlreadyUploadedFile = this.isAlreadyUploadedFile(fotoName);
    if (isAlreadyUploadedFile) {
      // Opening file in new tab
      window.open(this.getEncodedUrl(fotoName, token));

      return;
    }

    // Fetch file from indexedDB
    const filesList = await this.idbService.getUploadedFilesList(
      whereConditions,
      structureType
    );
    // If file is not present in indexedDB
    if (!filesList.length) {
      this.confirm({
        isCancelButtonVisible: false,
        message: 'Preview of this file is not available',
        title: 'Info',
      });

      return;
    }

    // Opening file in new tab
    this.openFileInNewTab(filesList[0].file);
  }

  refreshSession() {
    this.inspectionService.getOnlineStatus().then((isOnline) => {
      if (isOnline) {
        this.keycloakService
          .updateToken(environment.auth.token_lifespan)
          .then((updated: boolean) => {
            if (updated) {
              this.keycloakService.getToken().then((t) => {
                this.updateToken(t);
              });
            }
          })
          .catch((e) => {
            window.location.reload();
          });
      }
    });
  }

  setOnlineStatus(status: boolean) {
    this.isOnline$.next(status);
  }

  setTimeSlider(from?: moment.Moment, to?: moment.Moment) {
    this._timeWindow.now = moment().utc();
    if (!environment.production) {
      this._timeWindow.now.subtract(1, 'years');
    }
    this._timeWindow.from =
      from || moment(this._timeWindow.now).subtract(7, 'days');
    this._timeWindow.to = to || moment(this._timeWindow.now);
    this._timeWindowSbj.next(this._timeWindow);
  }

  showMessage(type: MessageType, msg: string, msgParams?: Object): void {
    const snackBarConfig = this.getSnackBarConfig(type);

    this.snackBar.open(
      this.translateService.instant(msg, msgParams),
      'close',
      snackBarConfig
    );
  }

  showMessageWithComponent(type: MessageType, data: any, component: any): void {
    const snackBarConfig = this.getSnackBarConfig(type);
    snackBarConfig.data = data;

    this.snackBar.openFromComponent(component, snackBarConfig);
  }

  timeWindow(): Observable<TimeWindow> {
    return this._timeWindowSbj.asObservable();
  }

  token(): Observable<string> {
    return this._tokenSbj.asObservable();
  }

  updateToken(token: string): void {
    localStorage.setItem(STORAGE_KEYS.token, token);
    this.userService.updateToken(token);
    this._tokenSbj.next(token);
  }

  private async getImageDimensions(
    file: File
  ): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const img = new Image();
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
        img.onerror = (error) => {
          reject(error);
        };
        img.src = event.target.result;
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  private getSnackBarConfig(type: MessageType): MatSnackBarConfig {
    const snackBarConfig = new MatSnackBarConfig();
    switch (type) {
      case MessageType.Info:
        snackBarConfig.duration = environment.snackbar_duration;
        snackBarConfig.panelClass = ['snackBarMessage', 'infoMessage'];
        break;
      case MessageType.Error:
        snackBarConfig.duration = environment.snackbar_error_duration;
        snackBarConfig.panelClass = ['snackBarMessage', 'errorMessage'];
        break;
    }

    return snackBarConfig;
  }

  private async getTiffImageDimensions(
    file: File
  ): Promise<{ width: number; height: number }> {
    // If file is TIFF then getting dimensions using UTIF library
    const response = await file.arrayBuffer();
    const ifds = UTIF.decode(new Uint8Array(response));
    const imageWidth = ifds[0].t256[0]; // t256 represents width
    const imageHeight = ifds[0].t257[0]; // t257 represents height

    return { width: imageWidth, height: imageHeight };
  }

  private updateTime() {
    this.now = moment().utc();
    this.tsSbj.next(this.now);
  }
}
