import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DisableAfterClickDirective } from './disable-after-click/disable-after-click.directive';
import { DynamicDirective } from './dynamic-directive/dynamic.directive';
import { FeatureDirective } from './feature/feature.directive';
import { RoleDirective } from './role/role.directive';

const directives = [
  DisableAfterClickDirective,
  DynamicDirective,
  FeatureDirective,
  RoleDirective,
];

@NgModule({
  declarations: directives,
  imports: [CommonModule],
  exports: directives,
})
export class DirectivesModule {}
