import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { environment } from '../../../../../../environments/environment';
import { CubeService } from '../../../../../services/cube/cube.service';
import { FileUploadError } from '../../../../../shared/configs/file-upload-error.config';

@Component({
  selector: 'cube-file-upload-error-message',
  templateUrl: './file-upload-error-message.component.html',
  styleUrls: ['./file-upload-error-message.component.scss'],
})
export class FileUploadErrorMessageComponent {
  dimensionExceededImageList: string[];
  errorCode: string;
  fileUploadError: typeof FileUploadError = FileUploadError;
  formattedSizeLimit: string;
  maxImageWidthHeight: number;
  sizeExceededFilesList: string[];
  unableToGetImageDimensionsFilesList: string[];

  constructor(
    private cubeService: CubeService,
    @Inject(MAT_SNACK_BAR_DATA) private matSnackBarData: any
  ) {
    this.dimensionExceededImageList =
      this.matSnackBarData?.dimensionExceededImageList;
    this.errorCode = this.matSnackBarData?.errorCode;
    this.formattedSizeLimit = this.getFormattedSizeLimit();
    this.maxImageWidthHeight = environment.file_upload.maxImageWidthHeight;
    this.sizeExceededFilesList = this.matSnackBarData?.sizeExceededFilesList;
    this.unableToGetImageDimensionsFilesList =
      this.matSnackBarData?.unableToGetImageDimensionsFilesList;
  }

  private getFormattedSizeLimit(): string {
    const formattedSizeLimit = this.cubeService.formatSize(
      environment.file_upload.size_limit,
      2
    );

    return formattedSizeLimit;
  }
}
