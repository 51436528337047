import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '../core/core.module';
import { BreadCrumbToolbarComponent } from './components/bread-crumb-toolbar/bread-crumb-toolbar.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';

@NgModule({
  declarations: [
    BreadCrumbToolbarComponent,
    ConfirmationDialogComponent,
    LoaderComponent,
    ValidationErrorComponent,
  ],
  imports: [CommonModule, CoreModule],
  exports: [
    BreadCrumbToolbarComponent,
    ConfirmationDialogComponent,
    LoaderComponent,
    ValidationErrorComponent,
  ],
})
export class SharedModule {}
