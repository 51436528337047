import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { StructureConstants } from '../../../../../../assets/structure-config';

import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cube-open-camera',
  templateUrl: './open-camera.component.html',
  styleUrls: ['./open-camera.component.scss'],
})
export class OpenCameraComponent implements OnInit, OnDestroy {
  @ViewChild('rinaWebcam') rinaWebcam!: ElementRef;

  capturedImage: WebcamImage;
  currentHeight: number;
  currentWidth: number;
  isPreview: boolean = false;
  videoOptions: MediaTrackConstraints;

  private destroy$: Subject<void>;
  private trigger: Subject<void>;

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    private matDialogRef: MatDialogRef<OpenCameraComponent>,
    private mediaObserver: MediaObserver
  ) {
    this.destroy$ = new Subject<void>();
    this.trigger = new Subject<void>();
  }

  ngOnInit(): void {
    this.setEventListeners();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCaptureImage(webcamImage: WebcamImage): void {
    this.capturedImage = webcamImage;
    this.isPreview = true;
  }

  onClickCapture(): void {
    this.trigger.next();
  }

  onSubmitImage(): void {
    const file = this.convertBase64ToFile(this.capturedImage.imageAsDataUrl);
    this.matDialogRef.close(file);
  }

  triggerSnapshot(): void {
    this.trigger.next();
  }

  private convertBase64ToFile(imageDataUrl): File {
    // Step 2: Extract base64 data
    const base64Data = imageDataUrl.split(',')[1];

    // Step 3: Decode base64 data
    const decodedData = atob(base64Data);

    // Step 4: Convert to file object (you can also save it to a file)
    const byteArray = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      byteArray[i] = decodedData.charCodeAt(i);
    }

    const fileName = `${StructureConstants.webcamImage}${
      this.matDialogData?.webcamImageCount ?? 0 + 1
    }`;

    const file = new File([byteArray], `${fileName}.png`, {
      type: 'image/png',
    });

    return file;
  }

  private setEventListeners(): void {
    this.mediaObserver
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((change) => {
        const containerElement = this.rinaWebcam.nativeElement;

        if (change.length <= 1) return;

        switch (change[0].mqAlias) {
          case 'sm':
            this.videoOptions = {
              aspectRatio: { ideal: 0.66666666666 },
            };
            this.currentHeight = Math.min(containerElement.clientHeight, 600);
            this.currentWidth = containerElement.clientWidth;

            break;

          case 'xs':
            this.videoOptions = {
              aspectRatio: { ideal: 0.5625 },
            };

            this.currentHeight = Math.min(containerElement.clientHeight, 600);
            this.currentWidth = containerElement.clientWidth;

            break;

          case 'xl':
            this.videoOptions = {
              aspectRatio: { ideal: 1.8962962963 },
            };
            this.currentHeight = (containerElement.clientHeight * 90) / 100;
            this.currentWidth = (containerElement.clientWidth * 90) / 100;

            break;

          default:
            this.videoOptions = {
              aspectRatio: { ideal: 1.7777777778 },
            };
            this.currentHeight = (containerElement.clientHeight * 90) / 100;
            this.currentWidth = (containerElement.clientWidth * 90) / 100;

            break;
        }
      });
  }
}
