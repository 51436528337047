import { Injectable } from '@angular/core';
import { HttpContext } from '@angular/common/http';

import { Response } from '../../../models/api';
import { Inspection } from '../../../models/inspection-crud';
import { EXCLUDE_LOADER } from '../../../shared/utils/http-context';
import { API } from '../api.const';
import { ApiService } from '../api.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InspectionCrudService extends ApiService {
  endpoint = API.INSPECTION_CRUD;

  createInspection(inspection): Observable<Response<Inspection>> {
    const context = new HttpContext().set(EXCLUDE_LOADER, true);

    return this.post<Response<Inspection>>({
      body: inspection,
      operation: 'createInspection',
      metadata: { context },
      url: this.endpoint.createInspection(),
    });
  }

  getPlannedInspection(inspectionId: string): Observable<Response<Inspection>> {
    return this.get<Response<Inspection>>({
      operation: 'getPlannedInspection',
      url: this.endpoint.getPlannedInspection(inspectionId),
    });
  }

  getPlannedInspections(
    filters: any,
    page: number,
    limit: number
  ): Observable<Response<Inspection[]>> {
    return this.get<Response<Inspection[]>>({
      metadata: {
        ...this.getHttpParams({
          ...filters,
          limit,
          page,
        }),
      },
      operation: 'getPlannedInspections',
      url: this.endpoint.getPlannedInspections(),
    });
  }

  updateInspection(
    inspectionId: string,
    inspection: FormData
  ): Observable<Response<Inspection>> {
    const context = new HttpContext().set(EXCLUDE_LOADER, true);

    return this.put<Response<Inspection>>({
      body: inspection,
      operation: 'updateInspection',
      metadata: { context },
      url: this.endpoint.updateInspection(inspectionId),
    });
  }
}
