export enum AppFeature {
  app = 'app',
  appAddInspection = 'app-add-inspection',
  appAll = 'app-all',
  portalCalendarInspection = 'portal-calendar-inspection',
  portalGroupManagement = 'portal-group-management',
  portalHome = 'portal-home',
  portalInspectionImport = 'portal-inspection-import',
  portalInspectionList = 'portal-inspection-list',
  portalMap = 'portal-map',
  portalMigration = 'portal-migration',
  portalPlanInspection = 'portal-plan-inspection',
  portalReportStatus = 'portal-report-status',
  portalSingleReportStatus = 'portal-single-report-status',
  portalStatisticPeriodicReport = 'portal-statistic-periodic-report',
  portalTenantConfigurationManagement = 'portal-tenant-configuration-management',
  portalUserManagement = 'portal-user-management',
}
