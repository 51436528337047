<!-- Close Button -->
<button
  class="close-icon"
  tabindex="-1"
  type="button"
  mat-icon-button
  [mat-dialog-close]="note"
>
  <mat-icon>close</mat-icon>
</button>

<!-- Content -->
<mat-dialog-content>
  <!-- Title -->
  <h4>{{ title ?? "note_dialog.note" | translate }}</h4>

  <!-- Textarea -->
  <mat-form-field>
    <textarea
      cdkTextareaAutosize
      matInput
      cdkAutosizeMaxRows="10"
      cdkAutosizeMinRows="3"
      [disabled]="disabled"
      [(ngModel)]="note"
    ></textarea>
  </mat-form-field>
</mat-dialog-content>
