import { formatDate } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import {
  BridgeConstants,
  StructureConstants,
  TunnelConstants,
} from '../../../../../../assets/structure-config';
import { environment } from '../../../../../../environments/environment';
import { InspectionService } from '../../../../../services/api/inspection/inspection.service';
import {
  CubeService,
  MessageType,
} from '../../../../../services/cube/cube.service';
import { IdbService } from '../../../../../services/storage/idb.service';
import { StorageService } from '../../../../../services/storage/storage.service';
import { DefectDialogMode } from '../../../../../shared/configs/defect-dialog-mode.config';
import { StructureType } from '../../../../../shared/configs/structure-type.config';
import { FileUploadErrorMessageComponent } from '../file-upload-error-message/file-upload-error-message.component';
import { OpenCameraComponent } from '../open-camera/open-camera.component';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

interface DefectImageFile {
  file: File;
  isCameraImage: boolean;
}

interface DefectSection {
  newFiles: Set<DefectImageFile>;
  oldFiles: Record<string, string>;
  sectionNo: number;
}

@Component({
  selector: 'cube-upload-defect-files-dialog',
  templateUrl: './upload-defect-files-dialog.component.html',
  styleUrls: ['./upload-defect-files-dialog.component.scss'],
})
export class UploadDefectFilesDialogComponent implements OnInit, OnDestroy {
  @Input() localizzazioneParams;

  @Output('saved') saved: EventEmitter<void> = new EventEmitter<void>();
  @Output() uploadDefectsClosed: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren('fileUpload') fileUploadEl: QueryList<
    ElementRef<HTMLInputElement>
  >;

  acceptedFileTypes: string[] = [];
  defectDialogMode: typeof DefectDialogMode = DefectDialogMode;
  deletedFileKeys: string[] = [];
  dialogMode: DefectDialogMode;
  disabled: boolean = false;
  maxSectionLimit: number = 5;
  newFiles: Set<DefectImageFile> = new Set();
  oldFiles: any;
  params: any;
  sectionList: DefectSection[] = [];

  private componentRowIndex: number;
  private deletedFileUrls: string[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  private fotoCount: number;
  private maxFilesPerSectionLimit: number = 4;
  private sectionNoList: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    private cubeService: CubeService,
    private idbService: IdbService,
    private inspectionService: InspectionService,
    private matDialog: MatDialog,
    private matDialogRef: MatDialogRef<UploadDefectFilesDialogComponent>,
    private storageService: StorageService
  ) {
    this.dialogMode =
      this.matDialogData.mode ?? DefectDialogMode.tunnelDefectUpload; // Mode will be present for all other cases
    this.setEventListeners();
  }

  ngOnInit(): void {
    this.params =
      this.dialogMode === DefectDialogMode.tunnelDefectUpload
        ? this.localizzazioneParams
        : this.matDialogData.params;
    this.acceptedFileTypes =
      this.params.acceptedFileTypes ??
      environment.file_upload.accepted_file_types.all;
    this.disabled = this.params.disabled;
    this.setSections();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFileNameWithoutSectionNo(fileName: string): string {
    return fileName.split(StructureConstants.sectionNo).shift();
  }

  getNewFilesSize(): number {
    let newFilesSize = 0;
    this.sectionList.forEach((section) => {
      newFilesSize += section.newFiles.size;
    });

    return newFilesSize;
  }

  isFileLimitReached(index: number): boolean {
    if (this.dialogMode !== DefectDialogMode.bridgeDefectUpload) return false;

    const addedFilesCount =
      Object.keys(this.sectionList[index].oldFiles).length +
      this.sectionList[index].newFiles.size;

    return addedFilesCount === this.maxFilesPerSectionLimit;
  }

  async onChangeFileUpload(index: number): Promise<void> {
    let element = this.fileUploadEl.toArray();
    const { files } = element[index].nativeElement;
    const addedFilesCount =
      Object.keys(this.sectionList[index].oldFiles).length +
      this.sectionList[index].newFiles.size;

    // Checking count of files per section
    if (
      this.dialogMode === DefectDialogMode.bridgeDefectUpload &&
      addedFilesCount + files.length > this.maxFilesPerSectionLimit
    ) {
      this.cubeService.showMessage(
        MessageType.Error,
        'upload_defect_files_dialog.files_per_section_limit_exceeded',
        { maxFilesPerSectionLimit: this.maxFilesPerSectionLimit }
      );

      return;
    }

    // Checking file types
    if (!this.cubeService.isFileTypeAccepted(files, this.acceptedFileTypes))
      return;

    // Checking file size limit and image dimensions limit
    const dimensionExceededImageList = [];
    const sizeExceededFilesList = [];
    const unableToGetImageDimensionsFilesList = [];

    for (const file of files) {
      // If file exceeded file size limit
      if (file.size > environment.file_upload.size_limit) {
        sizeExceededFilesList.push(file.name);
        continue;
      }

      // If file is an image
      if (
        environment.file_upload.accepted_file_types.images.includes(file.type)
      ) {
        try {
          // If image exceeded dimensions limit
          if (await this.cubeService.isImageDimensionAccepted(file)) {
            dimensionExceededImageList.push(file.name);
            continue;
          }
        } catch (error) {
          // If unable to get image dimensions
          unableToGetImageDimensionsFilesList.push(file.name);
          continue;
        }
      }

      this.sectionList[index].newFiles.add({
        file,
        isCameraImage: false,
      });
    }

    // If any file exceeded file size limit or dimension limit
    if (
      sizeExceededFilesList.length ||
      dimensionExceededImageList.length ||
      unableToGetImageDimensionsFilesList.length
    ) {
      // Showing error message
      this.cubeService.showMessageWithComponent(
        MessageType.Error,
        {
          dimensionExceededImageList,
          sizeExceededFilesList,
          unableToGetImageDimensionsFilesList,
        },
        FileUploadErrorMessageComponent
      );
    }

    // Clear the file input field
    element[index].nativeElement.value = '';
  }

  onClickAddSection(): void {
    this.addSection();
  }

  onClickCapture(index: number): void {
    const list = Object.keys(this.sectionList[index].oldFiles) ?? [];
    this.sectionList[index].newFiles.forEach((newFile) => {
      list.push(newFile.file.name);
    });
    const webCamImagesList = list.filter((file) =>
      file.includes(StructureConstants.webcamImage)
    );

    const dialogRef = this.matDialog.open(OpenCameraComponent, {
      height: '100vh',
      maxHeight: '100vh',
      maxWidth: '100vw',
      width: '100vw',
      data: { webcamImageCount: webCamImagesList.length + 1 },
    });

    dialogRef
      .afterClosed()
      .pipe(filter((file) => file))
      .subscribe((file: File) => {
        this.sectionList[index].newFiles.add({
          file,
          isCameraImage: true,
        });
      });
  }

  onClickDeleteNewFile(deletedFile: DefectImageFile, index: number): void {
    this.sectionList[index].newFiles.delete(deletedFile);
  }

  onClickDeleteOldFile(e: MouseEvent, key: string, url: string): void {
    e.preventDefault();
    e.stopPropagation();
    this.deletedFileKeys.push(key);
    this.deletedFileUrls.push(url);
  }

  onClickDeleteSection(index: number): void {
    this.cubeService
      .confirm({
        message: 'Are you sure you want to delete this section?',
      })
      .pipe(filter((isConfirm) => isConfirm))
      .subscribe(() => {
        // Deleting old files in section
        const oldFiles = Object.entries(this.sectionList[index].oldFiles);
        if (oldFiles.length) {
          oldFiles.forEach(([oldFileKey, oldFileUrl]) => {
            this.deletedFileKeys.push(oldFileKey);
            this.deletedFileUrls.push(oldFileUrl);
          });
        }

        // Deleting section from sectionNoList
        const sectionNo = this.sectionList[index].sectionNo;
        let sectionNoListIndex = this.sectionNoList.findIndex(
          (count) => count === sectionNo
        );
        if (sectionNoListIndex !== -1)
          this.sectionNoList.splice(sectionNoListIndex, 1);

        // Deleting section from sectionList
        this.sectionList.splice(index, 1);
      });
  }

  onClickNewFile(defectImageFile: DefectImageFile): void {
    this.cubeService.openFileInNewTab(defectImageFile.file);
  }

  async onClickOldFile({ key: fotoName, value: url }): Promise<void> {
    this.cubeService.openFilePreview(
      url,
      this.params.colDef.token,
      {
        fotoName,
        modelHash: this.params.colDef.modelData.modelHash,
      },
      this.params.colDef.modelData.structure_type
    );
  }

  onClickUndoDeleteFile(e: MouseEvent, key: string, url: string): void {
    e.preventDefault();
    e.stopPropagation();
    this.deletedFileKeys.splice(this.deletedFileKeys.indexOf(key), 1);
    this.deletedFileUrls.splice(this.deletedFileUrls.indexOf(url), 1);
  }

  onSubmitForm(): void {
    this.uploadAndDeleteFiles();
  }

  private addFileToSection(
    fileName: string,
    fileUrl: string,
    sectionNo: number
  ): void {
    const sectionListIndex = this.sectionList.findIndex(
      (el) => el.sectionNo === sectionNo
    );

    // Section already exists
    if (sectionListIndex !== -1) {
      this.sectionList[sectionListIndex].oldFiles[fileName] = fileUrl;

      return;
    }

    // Adding new section since section is not present in list
    this.sectionList.push({
      newFiles: new Set<DefectImageFile>(),
      oldFiles: { [fileName]: fileUrl },
      sectionNo,
    });
    this.sectionNoList.push(sectionNo);
  }

  private addSection(): void {
    const sortedArray = this.sectionNoList.sort((a, b) => a - b);
    const sectionNo = sortedArray.length
      ? sortedArray[sortedArray.length - 1] + 1
      : 1;
    this.sectionList.push({
      newFiles: new Set<DefectImageFile>(),
      oldFiles: {},
      sectionNo,
    });
    this.sectionNoList.push(sectionNo);
  }

  private deleteFiles(): void {
    const deletedFilesList = [];
    this.deletedFileKeys.forEach((deletedKey, index) => {
      // Adding file to deleted files list in local storage
      const isAlreadyUploadedFile = this.cubeService.isAlreadyUploadedFile(
        this.deletedFileUrls[index]
      );
      deletedFilesList.push({
        fileName: isAlreadyUploadedFile
          ? this.deletedFileUrls[index]
          : deletedKey,
        isDefect: true,
      });

      // Removing file path from data
      if (this.dialogMode === DefectDialogMode.tunnelDefectUpload) {
        delete this.params.data.L[this.params.localizzazioneKey]['N° foto'][
          deletedKey
        ];
      } else {
        this.params.isEventualiNoteSection
          ? delete this.params.data.Note[deletedKey]
          : delete this.params.data['N° foto'][deletedKey];
      }
    });

    // Updating deleted files list in local storage
    this.storageService.addDeletedFileUrlsToStorage(deletedFilesList);

    this.deletedFileUrls = [];
    this.deletedFileKeys = [];
  }

  private generateFileName(
    defectImageFile: DefectImageFile,
    index: number
  ): string {
    this.fotoCount++;
    this.inspectionService.setImageCount$(this.fotoCount);

    // Setting foto count and codice difetto
    let fileName = `Foto ${this.fotoCount} - ${this.params.data['Codice difetto']}`;

    // Setting descrizione difetto (will be skipped if the image is eventauli note)
    if (this.params.data['Descrizione difetto']) {
      fileName += ` - ${this.params.data['Descrizione difetto']}`;
    }

    // Setting fotoCode (Combination of element, component, material, direzione)
    if (this.params.data.fotoName) {
      fileName += ` - ${this.params.data.fotoName}`;
    }

    // Setting date (only for camera images)
    if (defectImageFile.isCameraImage) {
      const formattedDate = formatDate(
        defectImageFile.file['lastModified'],
        'dd/MM/YYYY HH:mm',
        'en-US'
      );
      fileName += ` - ${formattedDate}`;
    }

    // Setting section number (only for bridge defects)
    if (this.dialogMode === DefectDialogMode.bridgeDefectUpload) {
      fileName += ` ${StructureConstants.sectionNo}${this.sectionList[index].sectionNo}`;
    }

    return fileName;
  }

  private groupImagesIntoSections(): void {
    const nFoto = this.params.data['N° foto'];
    if (!nFoto) {
      this.addSection();

      return;
    }

    // Adding existing files into sections
    Object.entries(nFoto).forEach(([fileName, fileUrl]: [string, string]) => {
      // Getting section number
      const sectionNoIndex = fileName.indexOf(StructureConstants.sectionNo);

      const sectionNo = Number(
        fileName.slice(sectionNoIndex + StructureConstants.sectionNo.length)
      );

      this.addFileToSection(fileName, fileUrl, sectionNo);
    });
  }

  private setEventListeners(): void {
    // ImageCount$
    this.inspectionService
      .getImageCount$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageCount) => {
        this.fotoCount = imageCount;
      });

    // DefectRowIndex$
    this.inspectionService
      .getDefectRowIndex$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((rowIndex) => {
        this.componentRowIndex = rowIndex;
      });
  }

  private setSections(): void {
    switch (this.dialogMode) {
      case DefectDialogMode.bridgeDefectUpload:
        // Bridge - Opening from N Foto column
        this.groupImagesIntoSections();

        break;

      case DefectDialogMode.elementiAccessori:
        // Bridge - Opening from Elementi Accessori
        this.addSection();
        this.sectionList[0].oldFiles = this.params.data['N° foto'];

        break;

      case DefectDialogMode.bridgeEventualiNote:
        // Bridge - Opening from eventuali note
        this.addSection();
        this.sectionList[0].oldFiles = this.params.data.Note;

        break;

      case DefectDialogMode.tunnelDefectUpload:
        // Tunnel - Opening from N Foto inside L column
        this.addSection();
        this.sectionList[0].oldFiles = this.params.data.L
          ? this.params.data.L[this.params.localizzazioneKey]['N° foto']
          : '';

        break;

      case DefectDialogMode.tunnelDefectView:
        // Tunnel - Opening from N Foto column
        this.addSection();
        this.sectionList[0].oldFiles = this.params.data.L
          ? {
              ...this.params.data.L.sezioneLongitudinale1['N° foto'],
              ...this.params.data.L.sezioneLongitudinale2['N° foto'],
              ...this.params.data.L.sezioneLongitudinale3['N° foto'],
              ...this.params.data.L.sezioneLongitudinale4['N° foto'],
            }
          : {};

        break;
    }
  }

  private async uploadAndDeleteFiles(): Promise<void> {
    // Delete deleted files
    if (this.deletedFileKeys.length != 0) {
      this.deleteFiles();
    }

    // Upload new files
    if (this.getNewFilesSize() != 0) {
      await this.uploadFiles();
    }

    if (this.dialogMode === DefectDialogMode.tunnelDefectUpload) {
      // Tunnel
      this.uploadDefectsClosed.emit(this.params);
    } else {
      // Bridge
      this.saved.emit();
      this.matDialogRef.close();
    }
  }

  private async uploadFiles(): Promise<void> {
    const { componentType } = this.params.colDef;
    const { structure_type: structureType } = this.params.colDef.modelData;
    const newIdbFile: any = {
      defect: componentType,
      defectElementListName: `${componentType
        .replace(/\s/g, '')
        .toLowerCase()}list`,
      defectElementRowIndex: this.componentRowIndex,
      defectTemplateTableRowIndex: this.params.rowIndex,
      modelHash: this.params.colDef.modelData.modelHash,
    };

    // Setting values of fields in newIdbFile which are based on structure type
    switch (structureType) {
      case StructureType.bridge:
        if (componentType === BridgeConstants.elementiAccessori) {
          Object.assign(newIdbFile, {
            campataNo: -1,
            casoType: this.params.colDef.casoType,
            defectElementListName: this.params.context.parentField.key,
            defectElementRowIndex: -1,
          });
        } else {
          Object.assign(newIdbFile, {
            campataNo:
              this.params.context.parentField.parent.formControl.value[
                BridgeConstants.partNo
              ],
            casoType: -1,
          });
        }

        break;

      case StructureType.tunnel:
        const sezioneLongitudinale = this.params.localizzazioneKey.replace(
          'sezioneLongitudinale',
          ''
        );
        Object.assign(newIdbFile, {
          concioNo:
            this.params.context.parentField.parent.formControl.value[
              TunnelConstants.partNo
            ],
          sezioneLongitudinale,
        });

        break;
    }

    const newFiles = {};
    const newIdbFilesList: any[] = [];
    const newFileNamesList: string[] = [];

    // Looping through each section
    for (let i = 0; i < this.sectionList.length; i++) {
      // Looping through each new file in section
      for (let newDefectImageFile of this.sectionList[i].newFiles) {
        // Converting TIFF to PNG
        if (this.cubeService.isTiffFile(newDefectImageFile.file)) {
          newDefectImageFile.file = await this.cubeService.convertTiffToPNG(
            newDefectImageFile.file
          );
        }

        // Creating unique file name
        const fileName = this.generateFileName(newDefectImageFile, i);

        // Creating new indexedDB file
        newIdbFilesList.push({
          ...newIdbFile,
          file: newDefectImageFile.file,
          fotoName: fileName,
        });

        // Adding to new file names list
        newFileNamesList.push(fileName);

        newFiles[fileName] = '';
      }
    }

    // Adding new files to indexedDB
    await this.idbService.addUploadedFiles(newIdbFilesList, structureType);

    // Clearing new files
    this.sectionList.forEach((section) => {
      section.newFiles.clear();
    });

    // Updating existing data
    let key;
    let parent;
    if (this.dialogMode === DefectDialogMode.tunnelDefectUpload) {
      // Tunnel
      key = 'N° foto';
      parent = this.params.data.L[this.params.localizzazioneKey];
    } else {
      // Bridge
      key = this.params.isEventualiNoteSection ? 'Note' : 'N° foto';
      parent = this.params.data;
    }
    parent[key] = {
      ...parent[key],
      ...newFiles,
    };

    // Adding new files to savedImagesList in storage
    this.storageService.addSavedFileUrlsToStorage(newFileNamesList);
  }
}
