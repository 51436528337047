import { KeycloakConfig } from 'keycloak-js';
import { NgxLoggerLevel } from 'ngx-logger';

const keycloakConfig: KeycloakConfig = {
  url: 'https://login.cube.rina.org/auth',
  realm: 'cube',
  clientId: 'bms',
};

const companyConfig: any = {
  logo: './assets/logo.png',
  logoBg: 'bgffd300',
  name: 'BMS',
  role: '*',
};

const grantedMailAddresses: string[] = [];

const paginationConfig = {
  pageSize: 25,
  pageSizeOptions: [10, 25, 100],
  showFirstLastButtons: true,
};

const acceptedFileTypes = {
  imageTypes: ['image/jpeg', 'image/png', 'image/tiff'],
  pdfTypes: ['application/pdf'],
};
const uniqueAcceptedFileTypes = new Set([
  ...Object.values(acceptedFileTypes).flat(),
]);

export const globalEnvironment = {
  name: 'BMS',
  production: false,
  keycloak: keycloakConfig,
  company: companyConfig,
  map: {
    mapBox:
      'pk.eyJ1IjoiYWxibXUiLCJhIjoiY2p0eTN3emR5MGR1bDN5cjN3b2pvOW1pNSJ9.aB49CLoKOHwwUsnIPoFJgg',
    weather: '',
  },
  auth: {
    refreshing_time: 10000,
    token_lifespan: 60,
    bearerExcludedUrls: ['//wms.tidetech.org', '//wmts.tidetech.org'],
    grantedMailAddresses,
  },
  api: {
    seismicEvents: 'seismic/events',
    geojson: 'seismic/geojson',
  },
  logger: {
    serverLoggingUrl: 'http://localhost:3000/logs',
    level: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.ERROR,
  },
  render_delay: 400,
  refreshing_time: '10s',
  warn_timeout: 5000,
  error_timeout: 10000,
  snackbar_duration: 1500,
  snackbar_error_duration: 3000,
  file_upload: {
    maxImageWidthHeight: 65500,
    size_limit: 20000000,
    accepted_file_types: {
      all: [...uniqueAcceptedFileTypes],
      images: acceptedFileTypes.imageTypes,
      imagesWithoutTiff: acceptedFileTypes.imageTypes.filter(
        (type) => type !== 'image/tiff'
      ),
      imagesAndPdf: [
        ...acceptedFileTypes.imageTypes,
        ...acceptedFileTypes.pdfTypes,
      ],
      pdf: acceptedFileTypes.pdfTypes,
    },
  },
  sync_settings: {
    retry_delay: 2500, // This will increase with each retry attempt
  },
  year_filter_limit: 3,
  date_format: 'MM-dd-yyyy HH:mm',
  first_available_date: '2021-01-01',
  supportUrl: 'https://support.cube.rina.org',
  google: {
    analytics: {
      active: false,
      uaId: 'UA-128968550-2',
      logging: {
        debug: false,
        exceptions: true,
        verbose: false,
      },
    },
  },
  paginationConfig,
};
