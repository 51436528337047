<!-- Close Button -->
<button
  class="close-icon"
  tabindex="-1"
  type="button"
  mat-dialog-close
  mat-icon-button
>
  <mat-icon>close</mat-icon>
</button>

<!-- Content -->
<mat-dialog-content>
  <div class="dialog-form">
    <p>
      <span>
        {{ "sync_success_dialog.sync_success" | translate }}
      </span>
    </p>

    <div class="sync-details">
      <!-- Codice IOP -->
      <p>
        <span>{{ "sync_success_dialog.codice_iop" | translate }}: </span>
        {{ codiceIop }}
      </p>

      <!-- Nome Ponte/Viadotto -->
      <p>
        <span>{{ "sync_success_dialog.nome_ponte" | translate }}: </span>
        {{ nomePonte }}
      </p>
    </div>
  </div>
</mat-dialog-content>
