<!-- Close Button -->
<button
  class="close-icon"
  tabindex="-1"
  type="button"
  mat-dialog-close
  mat-icon-button
>
  <mat-icon>close</mat-icon>
</button>

<!-- Title -->
<h5 mat-dialog-title>Capture Image</h5>
<!-- Content -->
<mat-dialog-content>
  <div
    class="camera-session"
    #rinaWebcam
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
  >
    <!-- Image Preview -->
    <img
      alt=""
      [src]="capturedImage.imageAsDataUrl"
      *ngIf="isPreview; else webcam"
    />

    <!-- Webcam View -->
    <ng-template #webcam>
      <webcam
        style="min-height: 85vh"
        [allowCameraSwitch]="true"
        [height]="currentHeight"
        [trigger]="triggerObservable"
        [videoOptions]="videoOptions"
        [width]="currentWidth"
        (imageCapture)="onCaptureImage($event)"
      ></webcam>
    </ng-template>

    <!-- Action Buttons -->
    <div
      class="action-buttons"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
    >
      <ng-container *ngIf="isPreview; else captureButton">
        <!-- Cancel -->
        <button type="button" mat-fab color="warn" (click)="isPreview = false">
          <mat-icon>close</mat-icon>
        </button>

        <!-- Done -->
        <button type="button" mat-fab color="primary" (click)="onSubmitImage()">
          <mat-icon>done</mat-icon>
        </button>
      </ng-container>

      <!-- Click Camera Button -->
      <ng-template #captureButton>
        <button
          type="button"
          mat-fab
          color="primary"
          (click)="onClickCapture()"
        >
          <mat-icon>photo_camera</mat-icon>
        </button>
      </ng-template>
    </div>
  </div>
</mat-dialog-content>
