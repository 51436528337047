import { Injectable } from '@angular/core';

import { Profile, RoleModule, Tenant } from '../../../models/cube';
import { API } from '../api.const';
import { ApiService } from '../api.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminToolService extends ApiService {
  endpoint = API.ADMIN_TOOL;

  getAllTenants(username: string): Observable<string[]> {
    return this.get<Tenant[]>({
      operation: 'getAllTenants',
      url: this.endpoint.tenants(username),
    }).pipe(map((res) => res?.map((tenant) => tenant.name)));
  }

  getProfile(username: string): Observable<Profile> {
    return this.get<Profile>({
      operation: 'getProfile',
      url: this.endpoint.userProfile(username),
    });
  }

  getRoleFeatures(role: string): Observable<RoleModule> {
    return this.get<RoleModule>({
      operation: 'getRoleFeatures',
      url: this.endpoint.roleFeatures(role),
    });
  }

  /* getRoleMeasures(role: string): Observable<any> {
    return this.get<any>({
      operation: 'getRoleMeasures',
      url: this.endpoint.roleMeasures(role),
    });
  } */
}
