<!-- Close Button -->
<button
  class="close-icon"
  tabindex="-1"
  type="button"
  mat-dialog-close
  mat-icon-button
  *ngIf="dialogMode !== defectDialogMode.tunnelDefectUpload"
>
  <mat-icon>close</mat-icon>
</button>

<!-- Title -->
<h5 mat-dialog-title>
  {{
    dialogMode === defectDialogMode.tunnelDefectView
      ? "View Images"
      : "Upload Images"
  }}
</h5>

<!-- Content -->
<mat-dialog-content>
  <!-- Form -->
  <form class="dialog-form" (ngSubmit)="onSubmitForm()">
    <!-- Accepted File Types -->
    <p class="accepted-file-types">
      {{ "Accepted File Types: " + acceptedFileTypes.join(", ") }}
    </p>

    <div *ngFor="let section of sectionList; let i = index">
      <!-- Section Title -->
      <div
        class="section-name"
        *ngIf="dialogMode === defectDialogMode.bridgeDefectUpload"
      >
        <!-- Name -->
        <h3>Section {{ i + 1 }}</h3>

        <!-- Delete -->
        <span
          class="del-btn"
          *ngIf="!disabled"
          (click)="onClickDeleteSection(i)"
        >
          <mat-icon class="delete-icon">delete</mat-icon>
        </span>
      </div>

      <!-- Add Files -->
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
        *ngIf="dialogMode !== defectDialogMode.tunnelDefectView"
      >
        <!-- Add Button -->
        <button
          class="add-files-btn"
          type="button"
          mat-raised-button
          color="primary"
          [disabled]="isFileLimitReached(i) || disabled"
          (click)="fileUpload.click()"
        >
          Add Files
        </button>

        <!-- File Input -->
        <input
          multiple="multiple"
          style="display: none"
          type="file"
          #fileUpload
          observeFiles
          [accept]="acceptedFileTypes"
          (change)="onChangeFileUpload(i)"
        />

        <!-- Open Camera -->
        <button
          class="add-files-btn"
          type="button"
          mat-raised-button
          color="primary"
          [disabled]="isFileLimitReached(i) || disabled"
          (click)="onClickCapture(i)"
        >
          Capture
        </button>
      </div>

      <mat-action-list>
        <!-- Old Files -->
        <ng-container *ngFor="let oldFile of section.oldFiles | keyvalue">
          <!-- File Name -->
          <a
            mat-list-item
            class="list-files"
            [ngClass]="{ strike: deletedFileKeys.includes(oldFile.key) }"
            (click)="onClickOldFile(oldFile)"
          >
            {{ getFileNameWithoutSectionNo(oldFile.key) }}

            <!-- Delete -->
            <ng-container
              *ngIf="dialogMode !== defectDialogMode.tunnelDefectView"
            >
              <ng-container *ngIf="!disabled">
                <span
                  class="del-btn"
                  (click)="
                    onClickDeleteOldFile($event, oldFile.key, oldFile.value, i)
                  "
                  *ngIf="
                    !deletedFileKeys.includes(oldFile.key);
                    else undoButton
                  "
                >
                  <mat-icon class="delete-icon">delete</mat-icon>
                </span>
              </ng-container>
            </ng-container>
          </a>

          <!-- Undo -->
          <ng-template #undoButton>
            <span
              class="del-btn"
              (click)="
                onClickUndoDeleteFile($event, oldFile.key, oldFile.value)
              "
            >
              <mat-icon class="delete-icon">undo</mat-icon>
            </span>
          </ng-template>
        </ng-container>

        <!-- New Files -->
        <a
          mat-list-item
          class="list-files"
          (click)="onClickNewFile(newFile, i)"
          *ngFor="let newFile of section.newFiles"
        >
          {{ newFile.file.name }}

          <!-- Delete -->
          <span
            class="del-btn"
            (click)="onClickDeleteNewFile(newFile, i)"
            *ngIf="!disabled"
          >
            <mat-icon class="delete-icon">delete</mat-icon>
          </span>
        </a>
      </mat-action-list>
    </div>

    <!-- Action Buttons -->
    <div class="action-buttons">
      <!-- Add Section -->
      <button
        type="button"
        mat-raised-button
        color="primary"
        [disabled]="sectionList.length >= maxSectionLimit || disabled"
        (click)="onClickAddSection()"
        *ngIf="dialogMode === defectDialogMode.bridgeDefectUpload"
      >
        Add Section
      </button>

      <!-- Cancel -->
      <button
        type="button"
        mat-dialog-close
        mat-flat-button
        color="warn"
        *ngIf="dialogMode !== defectDialogMode.tunnelDefectUpload"
      >
        {{ "common.cancel" | translate }}
        <mat-icon>remove_circle_outline</mat-icon>
      </button>

      <!-- Save -->
      <button
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="disabled"
        [cubeDisableAfterClick]="
          deletedFileKeys.length === 0 && getNewFilesSize() === 0
        "
        *ngIf="dialogMode !== defectDialogMode.tunnelDefectView"
      >
        Save
      </button>
    </div>
  </form>
</mat-dialog-content>
