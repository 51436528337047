import { globalEnvironment } from './global';
import { BASE_URL } from './commons/base-url';
import { TI_BSM_COMMON_ENV } from './commons/bms';
import { MODULES } from './module.config';

export const environment = {
  ...globalEnvironment,
  ...BASE_URL,
  ...TI_BSM_COMMON_ENV,
  routes: [
    MODULES['about-module'].route,
    MODULES['configuration-module'].route,
    MODULES['import-inspections-module'].route,
    MODULES['synchronize-module'].route,
    MODULES['to-do-module'].route,
  ],
  api: {
    ...globalEnvironment.api,
  },
  auth: {
    ...globalEnvironment.auth,
    bearerExcludedUrls: [
      ...globalEnvironment.auth.bearerExcludedUrls,
    ],
  },
  devMode: {
    // INFO: When enabled, will disable defect template validations and allow to save defect template without filling all fields (default value = false)
    disableDefectsValidation: false,
    // INFO: When enabled, will disable models, files being deleted from indexed DB during syncing (default value = false)
    disableSync: false,
    // INFO: When enabled, will ignore required fields and enable all tabs (default value = false)
    enableAllTabs: false,
    // INFO: When enabled, will load the local input template (default value = false)
    loadLocalInputTemplate: false,
    // INFO: When enabled, will enable the debug panel (default value = false)
    showDebugPanel: false,
    // INFO: When enabled, will load defects from the local combined defects file instead of API (default value = false)
    useLocalCombinedDefects: false,
  }, // TEMP: For development
};
