import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'cube-sync-success-dialog',
  templateUrl: './sync-success-dialog.component.html',
  styleUrls: ['./sync-success-dialog.component.scss'],
})
export class SyncSuccessDialogComponent {
  codiceIop: string;
  nomePonte: string;

  constructor(@Inject(MAT_DIALOG_DATA) private matDialogData: any) {
    this.codiceIop = this.matDialogData.codiceIop;
    this.nomePonte = this.matDialogData.nomePonte;
  }
}
