import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {
  BridgeConstants,
  StructureConstants,
} from '../../../../../assets/structure-config';
import { DefectDialogMode } from '../../../../shared/configs/defect-dialog-mode.config';
import { UploadDefectFilesDialogComponent } from './upload-defect-files-dialog/upload-defect-files-dialog.component';

import { AgRendererComponent } from 'ag-grid-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cube-defect-table-file-upload-renderer',
  template: `
    <div *ngIf="params">
      <!-- Image Preview -->
      <button
        class="upload-btn filename"
        type="button"
        (click)="onClickUploadButton()"
      >
        <!-- Count With Sections -->
        <div
          class="display-flex-wrap"
          *ngIf="
            hasSections && sectionCountList.length;
            else countWithoutSections
          "
        >
          <span
            *ngFor="
              let section of sectionCountList;
              index as i;
              first as isFirst
            "
          >
            <span *ngIf="!isFirst">-</span> {{ i + 1 }}/{{ section }}
          </span>
        </div>

        <!-- Count Without Sections -->
        <ng-template #countWithoutSections>
          <span>{{ fileCount }}</span>
        </ng-template>

        <!-- Icon -->
        <mat-icon class="camera-icon" *ngIf="params.isEventualiNoteSection">
          file_upload
        </mat-icon>
      </button>
    </div>
  `,
  styles: [
    `
      .camera-icon {
        font-size: 25px;
      }

      .upload-btn {
        align-items: center;
        background-color: inherit;
        border: 0px;
        cursor: pointer;
        display: flex;
        gap: 17px;
        height: auto;
        justify-content: center;
        margin: 2px;
        width: 100%;

        > div {
          gap: 3px;
        }
      }

      .filename {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    `,
  ],
})
export class DefectTableFileUploadRendererComponent
  implements AgRendererComponent
{
  fileCount: string;
  hasSections: boolean;
  params: any;
  sectionCountList: number[] = [];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private matDialog: MatDialog) {}

  agInit(params: any): void {
    this.params = params;
    this.hasSections = !(
      'L' in this.params.data ||
      this.params.isEventualiNoteSection ||
      this.params.colDef.componentType === BridgeConstants.elementiAccessori
    );
    this.setFileCount(this.params);
  }

  onClickUploadButton() {
    let mode;
    switch (true) {
      case this.params.colDef.componentType ===
        BridgeConstants.elementiAccessori:
        mode = DefectDialogMode.elementiAccessori;

        break;

      case this.params.isEventualiNoteSection:
        mode = DefectDialogMode.bridgeEventualiNote;

        break;

      default:
        mode = DefectDialogMode.bridgeDefectUpload;
    }

    const dialogRef = this.matDialog.open(UploadDefectFilesDialogComponent, {
      width: '600px',
      height: '500px',
      data: {
        mode,
        params: this.params,
      },
    });

    dialogRef.componentInstance.saved
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.refresh(this.params);
      });
  }

  refresh(params: any): boolean {
    this.setFileCount(params);

    return true;
  }

  private setFileCount(params: any): void {
    const key = params.isEventualiNoteSection ? 'Note' : 'N° foto';

    if (!this.hasSections) {
      this.fileCount = params.data[key]
        ? Object.keys(params.data[key]).length.toString()
        : '0';

      return;
    }

    const sectionObj = {};
    const fileNamesList = Object.keys(params.data[key]);
    fileNamesList.forEach((fileName) => {
      const sectionNoIndex = fileName.indexOf(StructureConstants.sectionNo);
      if (sectionNoIndex === -1) return;

      const sectionNo = fileName.slice(sectionNoIndex);
      sectionObj[sectionNo] ??= 0;
      sectionObj[sectionNo] += 1;
    });

    this.sectionCountList = Object.values(sectionObj);
    // If no sections have been added
    if (!this.sectionCountList.length) {
      this.fileCount = '0';
    }
  }
}
