import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SyncError } from '../../../../../shared/configs/sync-error.config';

@Component({
  selector: 'cube-sync-failed-dialog',
  templateUrl: './sync-failed-dialog.component.html',
  styleUrls: ['./sync-failed-dialog.component.scss'],
})
export class SyncFailedDialogComponent {
  codiceIop: string;
  completedFiles: number;
  failedReason: string;
  nomePonte: string;
  totalFiles: number;

  constructor(@Inject(MAT_DIALOG_DATA) private matDialogData: any) {
    this.codiceIop = this.matDialogData.codiceIop;
    this.completedFiles = this.matDialogData.completedFiles;
    this.failedReason = this.getSyncFailedErrorMessage(
      this.matDialogData.failedReason
    );
    this.nomePonte = this.matDialogData.nomePonte ?? '-';
    this.totalFiles = this.matDialogData.totalFiles;
  }

  private getSyncFailedErrorMessage(failedReason: SyncError): string {
    switch (failedReason) {
      case SyncError.defectFilesBatchUpload:
        return 'sync_error.defect_files_batch_upload';

      case SyncError.inspectionFilesBatchUpload:
        return 'sync_error.inspection_files_batch_upload';

      case SyncError.saveInspection:
        return 'sync_error.save_inspection';

      case SyncError.uploadDefectFiles:
        return 'sync_error.upload_defect_files';

      case SyncError.uploadInspectionFiles:
        return 'sync_error.upload_inspection_files';

      default:
        return 'sync_error.unexpected_error';
    }
  }
}
