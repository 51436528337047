import { Module } from '../app/models/cube';
import { AppFeature } from '../app/shared/configs/app-feature.config';

// Top level routes are registered using this config
// Sidebar menu items are generated using this config and are shown in this order
export const MODULES: Record<string, Module> = {
  'to-do-module': {
    isAvailableOffline: true,
    roles: [AppFeature.app],
    route: {
      path: 'to-do',
      loadChildren: () =>
        import('../app/pages/to-do/to-do.module').then((m) => m.ToDoModule),
      data: { module: 'to-do-module' },
    },
    menu: {
      name: 'side_menu.to_do',
      mat_icon: 'home',
      open: false,
      link: 'to-do',
    },
  },
  'synchronize-module': {
    isAvailableOffline: false,
    roles: [AppFeature.app],
    route: {
      path: 'synchronize',
      loadChildren: () =>
        import('../app/pages/synchronize/synchronize.module').then(
          (m) => m.SynchronizeModule
        ),
      data: { module: 'synchronize-module' },
    },
    menu: {
      name: 'side_menu.synchronize',
      mat_icon: 'sync',
      open: false,
      link: 'synchronize',
    },
  },
  'import-inspections-module': {
    isAvailableOffline: false,
    roles: [AppFeature.app],
    route: {
      path: 'import',
      loadChildren: () =>
        import(
          '../app/pages/import-inspections/import-inspections.module'
        ).then((m) => m.ImportInspectionsModule),
      data: { module: 'import-inspections-module' },
    },
    menu: {
      name: 'side_menu.import_inspections',
      mat_icon: 'download',
      open: false,
      link: 'import',
    },
  },
  'configuration-module': {
    isAvailableOffline: false,
    roles: [AppFeature.app],
    route: {
      path: 'configuration',
      loadChildren: () =>
        import('../app/pages/configuration/configuration.module').then(
          (m) => m.ConfigurationModule
        ),
      data: {
        breadcrumb: 'breadcrumbs.configuration',
        module: 'app-configuration',
      },
    },
    menu: {
      name: 'side_menu.configuration',
      mat_icon: 'settings',
      open: false,
      link: false,
      sub: [
        {
          key: 'profile-page',
          name: 'side_menu.profile',
          mat_icon: 'account_circle',
          open: false,
          link: 'configuration/profile',
        },
      ],
    },
  },
  'profile-page': {
    isAvailableOffline: false,
    roles: [AppFeature.app],
  },
  'about-module': {
    isAvailableOffline: false,
    roles: [],
    route: {
      path: 'about',
      loadChildren: () =>
        import('../app/pages/about/about.module').then((m) => m.AboutModule),
      data: { module: 'about-module' },
    },
    menu: {
      name: 'side_menu.about',
      mat_icon: 'info',
      open: false,
      link: 'about',
    },
  },
};
