import { Component } from '@angular/core';

import { CubeService } from '../../../../services/cube/cube.service';

import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'cube-defect-table-radio-buttons-renderer',
  template: `<div *ngIf="params">
    <mat-radio-group
      class="display-flex-wrap"
      [disabled]="params.colDef.disabled"
      (change)="refresh(params)"
      (click)="changeEvent($event)"
      [(ngModel)]="params.value"
    >
      <!-- 0.2 -->
      <div class="flex-1">
        <mat-radio-button
          value="0.2"
          (click)="onClickRadioButton($event, '0.2')"
          *ngIf="params.data[optionType]['0.2']"
        ></mat-radio-button>
      </div>

      <!-- 0.5 -->
      <div class="flex-1">
        <mat-radio-button
          value="0.5"
          (click)="onClickRadioButton($event, '0.5')"
          *ngIf="params.data[optionType]['0.5']"
        ></mat-radio-button>
      </div>

      <!-- 1 -->
      <div class="flex-1">
        <mat-radio-button
          value="1"
          (click)="onClickRadioButton($event, '1')"
          *ngIf="params.data[optionType]['1']"
        ></mat-radio-button>
      </div>
    </mat-radio-group>
  </div>`,
  styles: [
    `
      :host {
        flex: 1;
      }

      .mat-radio-button {
        width: 100%;
        text-align: center;
      }

      .mat-radio-button ::ng-deep .mat-radio-label {
        width: auto;
      }

      .mat-radio-button ::ng-deep .mat-radio-label-content {
        display: none;
      }
    `,
  ],
})
export class DefectTableRadioButtonsRendererComponent
  implements AgRendererComponent
{
  key: any;
  optionType: any;
  params: any;

  constructor(private cubeService: CubeService) {}

  agInit(params: any): void {
    this.params = params;
    this.optionType =
      this.params.colDef.field == 'Estensione K1' ? 'K1 options' : 'K2 options';
    this.key = this.params.colDef.field;
  }

  changeEvent(e): void {
    if (this.params.data.NA || this.params.data.NP || this.params.data.NR) {
      this.cubeService.confirm({
        isCancelButtonVisible: false,
        message:
          'Cannot select K1 and K2 values when NA, NR or NP are selected.',
        title: 'Info',
      });
      e.preventDefault();
    }
  }

  onClickRadioButton(e: MouseEvent, value: string): void {
    if (this.params.value != value) return;

    e.preventDefault();
    delete this.params.value;
    this.refresh(this.params);
  }

  refresh(params: any): boolean {
    if (
      params.data.NA !== true &&
      params.data.NP !== true &&
      params.data.NR !== true
    ) {
      params.data[this.key] = params.value;
    } else {
      params.data[this.key] = params.value;
      params.api.refreshCells({
        rowNodes: [params.node],
        columns: [params.column],
      });
    }

    params.data.valorized =
      params.data['Estensione K1'] && params.data['Intensità K2'];

    return true;
  }
}
