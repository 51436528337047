import { Injectable } from '@angular/core';
import { HttpContext, HttpHeaders } from '@angular/common/http';

import {
  BridgeCombinedDefects,
  TunnelCombinedDefects,
} from '../../../../assets/structure-config';
import { environment } from '../../../../environments/environment';
import { Response } from '../../../models/api';
import { Inspection } from '../../../models/inspection-crud';
import { DefectSearchParams } from '../../../models/inspection';
import { EXCLUDE_LOADER } from '../../../shared/utils/http-context';
import { API } from '../api.const';
import { ApiService } from '../api.service';

import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InspectionService extends ApiService {
  endpoint = API.INSPECTION;

  private defectRowIndex$ = new BehaviorSubject(0);
  private imageCount$ = new BehaviorSubject(0);
  private repeatIndex$ = new BehaviorSubject(0);

  calculateAttentionClass(formData: any): Observable<Response<any>> {
    return this.post<any>({
      body: formData,
      operation: 'calculateAttentionClass',
      url: this.endpoint.attentionClassCalculation(),
    });
  }

  deleteUploadedFiles(urlList: string[]): Observable<any> {
    return this.delete<any>({
      body: { urls: urlList },
      operation: 'deleteUploadedFiles',
      url: this.endpoint.deleteInspectionMedia(),
    });
  }

  getBridgeDefects(
    defectSearchParams: DefectSearchParams,
    excludeLoader: boolean = false
  ): Observable<any> {
    if (environment.devMode.useLocalCombinedDefects)
      return this.getLocalBridgeDefects(defectSearchParams);

    const context = new HttpContext();
    if (excludeLoader) {
      context.set(EXCLUDE_LOADER, true);
    }

    return this.get<any>({
      metadata: {
        ...this.getHttpParams(defectSearchParams),
        context,
      },
      operation: 'getBridgeDefects',
      url: this.endpoint.defectsListBridge(),
    });
  }

  getDefectRowIndex$(): Observable<number> {
    return this.defectRowIndex$.asObservable();
  }

  getDefectRowIndex$Value(): number {
    return this.defectRowIndex$.value;
  }

  getInspection(inspectionId: string): Observable<Response<Inspection>> {
    return this.get<Response<Inspection>>({
      operation: 'getInspection',
      url: this.endpoint.getInspection(inspectionId),
    });
  }

  getInspectionAutocompleteList(
    searchString: string,
    isCustomer: '1' | '0'
  ): Observable<Response<Inspection[]>> {
    return this.get<Response<Inspection[]>>({
      metadata: {
        ...this.getHttpParams({
          is_customer: isCustomer,
          q: searchString,
        }),
      },
      operation: 'getInspectionAutocompleteList',
      url: this.endpoint.autocomplete(),
    });
  }

  getInspections(
    filters: any,
    page: number,
    limit: number
  ): Observable<Response<Inspection[]>> {
    return this.get<Response<Inspection[]>>({
      metadata: {
        ...this.getHttpParams({
          ...filters,
          limit,
          page,
        }),
      },
      operation: 'getInspections',
      url: this.endpoint.getInspections(),
    });
  }

  getImageCount$(): Observable<number> {
    return this.imageCount$.asObservable();
  }

  getOnlineStatus(): Promise<boolean> {
    const headers = new HttpHeaders().set(
      'x-api-key',
      '4dcf0f31-08b7-43c8-b674-aa94b9a65fa7'
    );

    const context = new HttpContext().set(EXCLUDE_LOADER, true);

    return this.httpClient
      .get(this.endpoint.isOnline(), { headers, context })
      .toPromise()
      .then((res) => true)
      .catch((err) => {
        // Status is 0 when offline, rest everything can be treated as online
        return err.status !== 0;
      });
  }

  getRepeatIndex$(): Observable<number> {
    return this.repeatIndex$.asObservable();
  }

  getTunnelDefects(
    defectType?: string,
    excludeLoader: boolean = false
  ): Observable<any> {
    if (environment.devMode.useLocalCombinedDefects)
      return this.getLocalTunnelDefects(defectType);

    const context = new HttpContext();
    if (excludeLoader) {
      context.set(EXCLUDE_LOADER, true);
    }
    return this.get<any>({
      metadata: {
        ...this.getHttpParams({ defectType }),
        context,
      },
      operation: 'getTunnelDefects',
      url: this.endpoint.defectsListTunnel(),
    });
  }

  setDefectRowIndex$(defectRowIndex: number): void {
    this.defectRowIndex$.next(defectRowIndex);
  }

  setImageCount$(imageCount: number): void {
    this.imageCount$.next(imageCount);
  }

  setRepeatIndex$(repeatIndex: number): void {
    this.repeatIndex$.next(repeatIndex);
  }

  takeInChargeInspection(data): Observable<any> {
    return this.post<any>({
      body: data,
      operation: 'takeInchargeInspection',
      url: this.endpoint.takeInChargeInspection(),
    });
  }

  updateInspectionStatus(
    inspectionId: string,
    formData: FormData
  ): Observable<any> {
    return this.put<any>({
      body: formData,
      operation: 'updateInspectionStatus',
      url: this.endpoint.updateInspectionStatus(inspectionId),
    });
  }

  uploadFiles(id: string, formData: FormData): Observable<any> {
    const context = new HttpContext().set(EXCLUDE_LOADER, true);

    return this.post<any>({
      body: formData,
      operation: 'uploadFiles',
      metadata: { context },
      url: this.endpoint.inspectionMediaUpload(id),
    });
  }

  private getLocalBridgeDefects(
    defectSearchParams: DefectSearchParams
  ): Observable<any> {
    // TEMP: For development
    const { component, material, elemento, element } = defectSearchParams;
    const combinedDefects = JSON.parse(JSON.stringify(BridgeCombinedDefects));
    const selectedDefectsList = [];

    if (element) {
      // If element was selected, then selecting defects under element
      selectedDefectsList.push(...combinedDefects[element]);
    } else {
      // If element was not selected, then selecting defects under all elements
      Object.values(combinedDefects).forEach((defectsList: any) => {
        selectedDefectsList.push(...defectsList);
      });
    }

    // Filtering defects based on component, material, elemento
    const filteredDefectsList = selectedDefectsList.filter((defect) => {
      const hasComponent =
        component != null ? defect.component === component : true;
      const hasMaterial =
        material != null ? defect.material === material : true;
      const hasElemento =
        elemento != null ? defect.elemento === elemento : true;

      return hasComponent && hasMaterial && hasElemento;
    });

    return of({ data: filteredDefectsList });
  }

  private getLocalTunnelDefects(defectType?: string): Observable<any> {
    // TEMP: For development
    const combinedDefects = JSON.parse(JSON.stringify(TunnelCombinedDefects));
    const selectedDefectsList = [];

    if (defectType) {
      // If defectType was selected, then selecting defects under defectType
      selectedDefectsList.push(...combinedDefects[defectType]);
    } else {
      // If defectType was not selected, then selecting defects under all defectType
      Object.values(combinedDefects).forEach((defectsList: any) => {
        selectedDefectsList.push(...defectsList);
      });
    }

    return of({ data: selectedDefectsList });
  }
}
