import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import {
  BridgeConstants,
  StructureConstants,
  TunnelConstants,
} from '../../../assets/structure-config';
import { environment } from '../../../environments/environment';
import { SyncingInspection } from '../../models/sync';
import { SyncFailedDialogComponent } from '../../pages/synchronize/shared/components/sync-failed-dialog/sync-failed-dialog.component';
import { SyncSuccessDialogComponent } from '../../pages/synchronize/shared/components/sync-success-dialog/sync-success-dialog.component';
import { InspectionStatus } from '../../shared/configs/inspection-status.config';
import { StructureType } from '../../shared/configs/structure-type.config';
import { SyncError } from '../../shared/configs/sync-error.config';
import { SyncStatus } from '../../shared/configs/sync-status.config';
import { InspectionCrudService } from '../api/inspection-crud/inspection-crud.service';
import { InspectionService } from '../api/inspection/inspection.service';
import { CubeService, MessageType } from '../cube/cube.service';
import { IdbService } from '../storage/idb.service';

import {
  BehaviorSubject,
  forkJoin,
  from,
  merge,
  Observable,
  of,
  Subject,
  timer,
} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  first,
  mergeMap,
  retryWhen,
  switchMap,
  take,
  takeUntil,
  tap,
  toArray,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SyncService implements OnDestroy {
  private batchSize: number;
  private cancelSyncInspection$: Subject<void> = new Subject<void>();
  private destroy$: Subject<void> = new Subject<void>();
  private initialProgress: number = 5;
  private maxApiRetryAttempts: number;
  private model: any;
  private modelId: number;
  private syncingInspection?: SyncingInspection;
  private syncingInspection$: BehaviorSubject<SyncingInspection> =
    new BehaviorSubject<SyncingInspection>(null);
  private uploadFileObservablesList: Observable<any>[] = [];

  constructor(
    private cubeService: CubeService,
    private idbService: IdbService,
    private inspectionCrudService: InspectionCrudService,
    private inspectionService: InspectionService,
    private matDialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.cancelSyncInspection$.complete();
  }

  cancelSyncInspection(): void {
    this.cubeService
      .confirm({
        title: 'synchronize.cancel_sync',
        message: 'synchronize.confirm_cancel_sync',
      })
      .pipe(filter((isConfirm) => isConfirm))
      .subscribe(() => {
        this.setSyncingInspection({ syncStatus: SyncStatus.cancelling });

        this.cancelSyncInspection$.next();
      });
  }

  getSyncingInspection$(): Observable<SyncingInspection> {
    return this.syncingInspection$.asObservable();
  }

  async onPageReload(): Promise<void> {
    if (!this.syncingInspection) return;

    try {
      await this.updateModelInIndexedDb();
    } finally {
      this.setSyncingInspection();
    }
  }

  async syncInspection(
    row: any,
    targetStatus: InspectionStatus,
    singleFilesList: any[],
    multiFilesList: any[],
    defectFilesList: any[]
  ): Promise<void> {
    const { model, id: modelId, modelHash } = row;
    this.model = model;
    this.modelId = modelId;

    // Setting syncingInspection
    const totalFiles =
      singleFilesList.length + multiFilesList.length + defectFilesList.length;
    const syncingInspection: SyncingInspection = {
      completedFiles: 0,
      modelHash,
      modelId: this.modelId,
      name: this.model[StructureConstants.levels.l0]['Codice IOP'],
      progress: 0,
      syncStatus: SyncStatus.preparing,
      totalFiles,
    };
    this.setSyncingInspection(syncingInspection);

    this.batchSize = await this.getBatchSize();
    this.maxApiRetryAttempts = this.batchSize; // Setting maxApiRetryAttempts same as batchSize to be dynamic
    this.uploadFileObservablesList = [];

    // Uploading Defect Files
    this.defectFilesBatchUpload(defectFilesList);

    // Uploading Files
    this.inspectionFilesBatchUpload(singleFilesList, 'single');

    // Uploading Files in L0 Media Tab
    this.inspectionFilesBatchUpload(multiFilesList, 'multiple');

    // Group upload APIs into batches
    const batches = this.getUploadFileObservablesBatches();
    from(batches)
      .pipe(
        tap(() => {
          if (!totalFiles) return;

          this.setSyncingInspection({
            progress: this.initialProgress,
            syncStatus: SyncStatus.uploadingFiles,
          });
        }),
        concatMap((batch) => forkJoin(batch)), // Sending each batch sequentially. All APIs in each batch are called in parallel
        toArray(), // Collecting all emitted values into an array
        tap(() =>
          this.setSyncingInspection({
            progress: 90,
            syncStatus: SyncStatus.savingInspection,
          })
        ),
        switchMap(() => this.saveInspection(targetStatus)), // Saving inspection
        filter((res) => res), // Filtering out if inspection save fails
        tap(() => this.setSyncingInspection({ progress: 95 })),
        takeUntil(this.cancelApi$())
      )
      .subscribe({
        // Success
        next: async () => {
          await this.onSyncSuccess();
        },
        // Error
        error: async (err) => {
          await this.onSyncFailed(SyncError.unexpectedError, true);
        },
      });
  }

  private cancelApi$(): Observable<void> {
    return merge(this.destroy$, this.cancelSyncInspection$).pipe(
      first(),
      tap(async () => {
        await this.onSyncCancelled();
      })
    );
  }

  private async clearInspectionDetailsFromIdb(): Promise<void> {
    // TEMP: Disable sync
    if (environment.devMode.disableSync) return;

    // Deleting the inspection model
    await this.idbService.deleteInspectionModel(this.modelId);

    // Deleting all media files under this inspection
    this.idbService.deleteAllUploadedFilesUnderInspection(
      this.model.modelHash,
      this.model.structure_type
    );
  }

  private defectFilesBatchUpload(defectFilesList: any[]): void {
    if (defectFilesList.length === 0) return;

    const duplicateDefectFilesList = [];
    let defectImageKeys = [];
    let fileNames = [];
    let fileSize = 0;
    let formData = new FormData();
    let mediaCount = 0;
    let pathList = [];

    try {
      // Grouping into batches for upload
      defectFilesList.forEach((defectFile) => {
        // If file won't fit in current batch
        if (
          fileSize + defectFile.file.size >
          environment.file_upload.size_limit
        ) {
          // Send current batch
          const uploadDefectFiles$ = this.uploadDefectFiles(
            formData,
            fileNames,
            pathList,
            defectImageKeys
          );
          this.uploadFileObservablesList.push(uploadDefectFiles$);

          // Create new batch
          defectImageKeys = [];
          fileNames = [];
          fileSize = 0;
          formData = new FormData();
          mediaCount = 0;
          pathList = [];
        }

        // If another file with same filename is present in current batch, skip file and add to duplicate list
        if (fileNames.find((x) => x.fileName === defectFile.file.name)) {
          duplicateDefectFilesList.push(defectFile);
          return;
        }

        // If filename is not present in current batch, add file to current batch
        defectImageKeys.push(defectFile.id);
        fileNames.push({
          fileName: defectFile.file.name,
          fotoName: defectFile.fotoName,
        });
        fileSize += defectFile.file.size;
        formData.append('media' + mediaCount, defectFile.file);
        mediaCount += 1;
        const parentTree = this.getDefectParent(defectFile);
        pathList.push(parentTree);
      });

      // Send current batch
      const uploadFile$ = this.uploadDefectFiles(
        formData,
        fileNames,
        pathList,
        defectImageKeys
      );
      this.uploadFileObservablesList.push(uploadFile$);

      // If files with same names were present
      if (duplicateDefectFilesList.length)
        this.defectFilesBatchUpload(duplicateDefectFilesList);
    } catch (err) {
      this.onSyncFailed(SyncError.defectFilesBatchUpload);

      throw err;
    }
  }

  private getUploadFileObservablesBatches(): Observable<any>[][] {
    const batches: Observable<any>[][] = [];
    for (
      let i = 0;
      i < this.uploadFileObservablesList.length;
      i += this.batchSize
    ) {
      batches.push(this.uploadFileObservablesList.slice(i, i + this.batchSize));
    }

    return batches;
  }

  private async getBatchSize(): Promise<number> {
    try {
      // Fetching local image and converting to file
      const imageRes = await fetch('assets/images/sample-image.jpg');
      const imageBlob = await imageRes.blob();
      const image = new File([imageBlob], 'sample-image.jpg', {
        type: 'image/jpg',
      });

      // Adding file to formData
      const formData = new FormData();
      formData.append('media1', image);

      // Calculating upload time
      const startTime = Date.now();
      await this.inspectionService
        .uploadFiles('speedTest', formData)
        .toPromise();
      const endTime = Date.now();
      // Converting milliseconds to seconds
      const duration = (endTime - startTime) / 1000;

      // Calculating upload speed
      // Converting bytes to Kilobits
      const fileSizeInKilobits = image.size / 125;
      const speedInKbps = fileSizeInKilobits / duration;

      // Setting batch size based on upload speed
      let batchSize;
      switch (true) {
        case speedInKbps >= 5000:
          batchSize = 5;

          break;

        case speedInKbps < 5000 && speedInKbps >= 2500:
          batchSize = 3;

          break;

        default:
          batchSize = 1;
      }

      return batchSize;
    } catch (err) {
      return 1;
    }
  }

  private getDefectParent(defectFile): any {
    const { modelstructure: modelStructure } =
      this.model[StructureConstants.levels.l0].opereparzialilist[0];

    let defectParent;
    switch (this.model.structure_type) {
      case StructureType.bridge:
        // Elementi Accessori
        if (defectFile.defect === BridgeConstants.elementiAccessori) {
          if (defectFile.casoType === 0) {
            // Caso0
            defectParent =
              modelStructure['Scheda Ispezione Ponti di Livello 1'][
                'Scheda Ispezione Ponti di Livello 1 List2'
              ][defectFile.defectTemplateTableRowIndex];
          } else {
            // Caso1, Caso2, Caso3
            defectParent =
              modelStructure[`Caso${defectFile.casoType}`][
                defectFile.defectElementListName
              ][defectFile.defectTemplateTableRowIndex];
          }
        } else {
          // Bridge Defects
          const partIndex = modelStructure[BridgeConstants.partsList].findIndex(
            (x) => x[BridgeConstants['partNo']] === defectFile.campataNo
          );

          defectParent =
            modelStructure[BridgeConstants.partsList][partIndex].modelstructure[
              defectFile.defectElementListName
            ][defectFile.defectElementRowIndex].modelstructure[
              'Scheda Ispezione Ponti di Livello 1'
            ]['Scheda Ispezione Ponti di Livello 1 List1'][
              defectFile.defectTemplateTableRowIndex
            ];
        }

        break;

      case StructureType.tunnel:
        // Tunnel Defects
        const partIndex = modelStructure[TunnelConstants.partsList].findIndex(
          (x) => x[TunnelConstants['partNo']] === defectFile.concioNo
        );
        const sezioneLongitudinaleValue = `sezioneLongitudinale${defectFile.sezioneLongitudinale}`;

        defectParent =
          modelStructure[TunnelConstants.partsList][partIndex].modelstructure[
            defectFile.defectElementListName
          ][defectFile.defectElementRowIndex].modelstructure[
            'Scheda Ispezione Ponti di Livello 1'
          ]['Scheda Ispezione Ponti di Livello 1 List1'][
            defectFile.defectTemplateTableRowIndex
          ]['L'][sezioneLongitudinaleValue];

        break;
    }

    return defectParent;
  }

  private inspectionFilesBatchUpload(
    inspectionFilesList: any[],
    type: 'single' | 'multiple'
  ): void {
    if (inspectionFilesList.length === 0) return;

    const duplicateFilesList = [];
    const fileNames = [];
    let fileKeys = [];
    let fileSize = 0;
    let formData = new FormData();
    let mediaCount = 1;
    let pathList = [];

    try {
      // Grouping into batches for upload
      inspectionFilesList.forEach((inspectionFile) => {
        // Updating the path of files added to levels other than L0 to use L0 path
        if (
          type === 'single' &&
          inspectionFile.keyStructure[0] !== StructureConstants.levels.l0
        ) {
          // Removing L1, L2
          let path: string[];
          switch (inspectionFile.keyStructure[0]) {
            case StructureConstants.levels.l1:
              inspectionFile.keyStructure.shift();
              path = ['opereparzialilist', '0'];

              break;

            case BridgeConstants.levels.level2:
              inspectionFile.keyStructure.shift();
              path = ['level2', 'level2List', '0'];

              break;
          }

          // Replacing with equivalent path in l0
          inspectionFile.keyStructure = [
            StructureConstants.levels.l0,
            ...path,
            'modelstructure',
          ].concat(inspectionFile.keyStructure);
        }

        const keyStructureCopy = [].concat(inspectionFile.keyStructure);
        const key = keyStructureCopy.pop();
        const parent = this.cubeService.getPropByPathList(
          this.model,
          keyStructureCopy
        );

        // If file won't fit in current batch
        if (
          fileSize + inspectionFile.file.size >
          environment.file_upload.size_limit
        ) {
          // Send current batch
          const uploadFile$ = this.uploadInspectionFiles(
            formData,
            fileKeys,
            pathList,
            type
          );
          this.uploadFileObservablesList.push(uploadFile$);

          // Create new batch
          fileKeys = [];
          fileSize = 0;
          formData = new FormData();
          mediaCount = 1;
          pathList = [];
        }

        if (fileNames.find((x) => x.fileName === inspectionFile.file.name)) {
          // If another file with same filename present in current batch, skip file and add to duplicate list
          duplicateFilesList.push(inspectionFile);
          return;
        }

        // If filename is not present in current batch, add file to current batch
        fileNames.push({
          fileName: inspectionFile.file.name,
        });
        fileKeys.push(inspectionFile.id);
        fileSize += inspectionFile.file.size;
        formData.append('media' + mediaCount, inspectionFile.file);
        mediaCount++;
        pathList.push({ parent: parent, key: key });
      });

      // Send current batch
      const uploadFile$ = this.uploadInspectionFiles(
        formData,
        fileKeys,
        pathList,
        type
      );
      this.uploadFileObservablesList.push(uploadFile$);

      // If files with same names were present
      if (duplicateFilesList.length)
        this.inspectionFilesBatchUpload(duplicateFilesList, type);
    } catch (err) {
      this.onSyncFailed(SyncError.inspectionFilesBatchUpload);

      throw err;
    }
  }

  private async onSyncCancelled(): Promise<void> {
    await this.updateModelInIndexedDb();

    this.setSyncingInspection({ syncStatus: SyncStatus.cancelled });

    this.cubeService.showMessage(
      MessageType.Error,
      'synchronize.sync_cancelled'
    );

    setTimeout(() => {
      // Clearing syncing inspection
      this.setSyncingInspection();
    }, 1500);
  }

  private async onSyncFailed(
    syncError: SyncError,
    hasToSaveModel: boolean = false
  ): Promise<void> {
    this.setSyncingInspection({ syncStatus: SyncStatus.failed });

    if (hasToSaveModel) await this.updateModelInIndexedDb();

    const dialogRef = this.openSyncFailedDialog(syncError);

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        // Clearing syncing inspection
        this.setSyncingInspection();
      });
  }

  private async onSyncSuccess(): Promise<void> {
    // Clearing inspection details from indexedDB
    await this.clearInspectionDetailsFromIdb();

    this.setSyncingInspection({
      progress: 100,
      syncStatus: SyncStatus.completed,
    });

    // Show sync success dialog
    const dialogRef = this.openSyncSuccessDialog();

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        // Clearing syncing inspection
        this.setSyncingInspection();
      });
  }

  private openSyncFailedDialog(
    failedReason: SyncError
  ): MatDialogRef<SyncFailedDialogComponent> {
    const { completedFiles, totalFiles } = this.syncingInspection;

    const dialogRef = this.matDialog.open(SyncFailedDialogComponent, {
      data: {
        codiceIop: this.model[StructureConstants.levels.l0]['Codice IOP'],
        completedFiles,
        failedReason,
        nomePonte:
          this.model[StructureConstants.levels.l0]['Nome Ponte Viadotto'],
        totalFiles,
      },
      disableClose: true,
      width: '600px',
    });

    return dialogRef;
  }

  private openSyncSuccessDialog(): MatDialogRef<SyncSuccessDialogComponent> {
    const dialogRef = this.matDialog.open(SyncSuccessDialogComponent, {
      data: {
        codiceIop: this.model[StructureConstants.levels.l0]['Codice IOP'],
        nomePonte:
          this.model[StructureConstants.levels.l0]['Nome Ponte Viadotto'],
      },
      disableClose: true,
    });

    return dialogRef;
  }

  private retryApi(errors) {
    return errors.pipe(
      mergeMap((err: any, i: number) => {
        if (err.status === 400 || err.status === 401) {
          // Retry after a delay if error is 400 or 401
          const retryAttempt = i + 1;

          if (retryAttempt <= this.maxApiRetryAttempts) {
            // Increasing delay for retry
            const retryDelay =
              environment.sync_settings.retry_delay * retryAttempt;

            return timer(retryDelay);
          }
        }

        // Throw the error if it's not 400 or 401 or if retry attempts exceeded
        throw err;
      }),
      take(this.maxApiRetryAttempts + 1) // Limit the number of retries
    );
  }

  private saveInspection(targetStatus: InspectionStatus): Observable<any> {
    // TEMP: Disable sync
    if (environment.devMode.disableSync) return of(true);

    const modelData = this.cubeService.getDeepCopy(this.model);
    modelData.status = targetStatus;
    const formData = new FormData();
    formData.append('data', JSON.stringify(modelData));

    // Save inspection based on present status of inspection
    const saveInspection$ =
      this.model.status === InspectionStatus.draft
        ? this.inspectionCrudService.createInspection(formData) // Create new inspection
        : this.inspectionCrudService.updateInspection(this.model.id, formData); // Update existing inspection

    return saveInspection$.pipe(
      catchError(() => {
        this.onSyncFailed(SyncError.saveInspection, true);

        return of(false);
      })
    );
  }

  private setSyncingInspection(
    syncingInspection?: Partial<SyncingInspection>
  ): void {
    if (syncingInspection) {
      // Set updated fields of syncingInspection
      this.syncingInspection = {
        ...this.syncingInspection,
        ...syncingInspection,
      };
    } else {
      // Clear syncingInspection
      delete this.syncingInspection;
    }

    this.setSyncingInspection$();
  }

  private setSyncingInspection$(): void {
    this.syncingInspection$.next(this.syncingInspection);
  }

  private updateFileUploadCount(fileCount: number): void {
    const completedFiles = this.syncingInspection.completedFiles + fileCount;
    const progress = (completedFiles / this.syncingInspection.totalFiles) * 85; // Converting to 85% since remaining 15 is used for other statuses

    this.setSyncingInspection({
      completedFiles,
      progress: this.initialProgress + Math.round(progress * 10) / 10, // Rounding to one decimal place
    });
  }

  private async updateModelInIndexedDb(): Promise<void> {
    // Updating model to indexedDb incase sync is cancelled or page is closed
    await this.idbService.updateInspectionModel(
      this.modelId,
      JSON.stringify(this.model)
    );
  }

  private uploadDefectFiles(
    formData,
    filenames,
    pathList,
    defectImageKeys: any[]
  ): Observable<any> {
    return this.inspectionService
      .uploadFiles(this.model.modelHash, formData)
      .pipe(
        retryWhen((errors) => this.retryApi(errors)),
        filter((res) => res),
        tap((res) => {
          try {
            let newFiles = {};
            Object.values(res.data).forEach((url, index) => {
              const fileName = filenames[index].fotoName;
              newFiles = {
                [fileName]: url,
              };
              const key =
                pathList[index]['Codice difetto'] ===
                BridgeConstants.eventualiNote
                  ? 'Note'
                  : 'N° foto';
              pathList[index][key] = {
                ...pathList[index][key],
                ...newFiles,
              };
            });

            // TEMP: Disable sync
            if (!environment.devMode.disableSync)
              this.idbService.deleteUploadedFilesByKeys(
                defectImageKeys,
                this.model.structure_type
              );

            this.updateFileUploadCount(defectImageKeys.length);
          } catch (err) {
            this.onSyncFailed(SyncError.uploadDefectFiles);

            throw err;
          }
        })
      );
  }

  private uploadInspectionFiles(
    formData,
    fileKeys,
    pathList,
    type: 'multiple' | 'single'
  ): Observable<any> {
    return this.inspectionService
      .uploadFiles(this.model.modelHash, formData)
      .pipe(
        retryWhen((errors) => this.retryApi(errors)),
        filter((res) => res),
        tap((res) => {
          try {
            Object.values(res.data).forEach((url, index) => {
              const parent = pathList[index]['parent'];
              const key = pathList[index]['key'];

              // Setting the URL into the model
              if (type === 'multiple') {
                const list = parent[key].filter((x) =>
                  this.cubeService.isAlreadyUploadedFile(x)
                );
                parent[key] = list.length ? list.concat([url]) : [url];
              } else if (type === 'single' && parent != null) {
                parent[key] = url;
              }
            });

            // TEMP: Disable sync
            if (!environment.devMode.disableSync)
              this.idbService.deleteUploadedFilesByKeys(fileKeys);

            this.updateFileUploadCount(fileKeys.length);
          } catch (err) {
            this.onSyncFailed(SyncError.uploadInspectionFiles);

            throw err;
          }
        })
      );
  }
}
