import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'cube-action-buttons-renderer',
  template: `
    <div class="button-container">
      <!-- Edit/View -->
      <button
        type="button"
        mat-flat-button
        color="primary"
        (click)="onClickEdit($event)"
      >
        {{ params.isViewMode ? 'View' : 'Edit' }}
      </button>

      <!-- Duplicate -->
      <button
        type="button"
        mat-flat-button
        color="info"
        (click)="onClickDuplicate($event)"
        *ngIf="!params.isViewMode && params.isDuplicateVisible"
      >
        Duplicate
      </button>

      <!-- Delete -->
      <button
        type="button"
        mat-flat-button
        color="warn"
        (click)="onClickDelete($event)"
        *ngIf="!params.isViewMode && params.isDeleteVisible"
      >
        Delete
      </button>
    </div>
  `,
  styles: [
    `
      .button-container {
        align-items: center;
        display: flex;
        gap: 15px;
        height: 100%;
        justify-content: center;
        width: 100%;
      }

      .mat-flat-button {
        font-weight: 500;
        line-height: 30px;
      }
    `,
  ],
})
export class ActionButtonsRendererComponent
  implements ICellRendererAngularComp
{
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  onClickDelete(e): void {
    this.params.onDelete(this.params.rowIndex, this.params.data);
  }

  onClickDuplicate(e): void {
    this.params.onDuplicate(this.params.rowIndex, this.params.data);
  }

  onClickEdit(e): void {
    this.params.onEdit(this.params.rowIndex, this.params.data);
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    throw new Error('Method not implemented.');
  }
}
