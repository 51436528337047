export const TunnelCombinedDefects = {
  // INFO: Defect 1
  defect1: [
    {
      'Codice difetto': 'Difetti causati dalla presenza di acqua',
      defectType: 'defect1',
      'Descrizione difetto': '',
      sectionHeader: true,
      valorized: true,
    },
    {
      'Codice difetto': '1.1',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Stillicidi',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.2',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': "Venute d'acqua",
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.3',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Concrezioni - depositi - incrostazioni',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.4',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Effetti del gelo - tracce di Sali',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.5',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Efflorescenze su malta o calcestruzzo',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': 'Difetti causati dal terreno circostante',
      defectType: 'defect1',
      'Descrizione difetto': '',
      sectionHeader: true,
      valorized: true,
    },
    {
      'Codice difetto': '1.6',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Vuoti e cavità',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.7',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Deterioramento dei portali di imbocco',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.8',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Instabilità dei pendii',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': 'Deterioramenti nelle sezioni non rivestite',
      defectType: 'defect1',
      'Descrizione difetto': '',
      sectionHeader: true,
      valorized: true,
    },
    {
      'Codice difetto': '1.9',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Distacco di porzioni da roccia stratificata',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.10',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Distacco di porzioni da roccia stratificata',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto':
        'Difetti dei materiali di rivestimento (pietra o muratura)',
      defectType: 'defect1',
      'Descrizione difetto': '',
      sectionHeader: true,
      valorized: true,
    },
    {
      'Codice difetto': '1.11',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': "Deterioramento superficiale a nido d'ape",
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.12',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Desquamazione',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.13',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Distaccamenti dovuti a carichi di compressione',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.14',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Distaccamenti dovuti a carichi di compressione',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.15',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Deterioramento dei letti di malta',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': 'Difetti dei materiali di rivestimento (calcestruzzo)',
      defectType: 'defect1',
      'Descrizione difetto': '',
      sectionHeader: true,
      valorized: true,
    },
    {
      'Codice difetto': '1.16',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Scheggiatura - distacchi',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.17',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Rigonfiamenti',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.18',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Lesioni e distacchi per carichi di compressione',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.19',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto':
        'Lesioni e distacchi per corrosione delle armature',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '1.20',
      'Correlazione con altri difetti': '',
      defectType: 'defect1',
      'Descrizione difetto': 'Deterioramento del calcestruzzo proiettato',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': 'Eventuali note',
      defectType: 'defect1',
      'Descrizione difetto': '',
      'Estensione K1': null,
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      valorized: true,
    },
  ],
  // INFO: Defect 2
  defect2: [
    {
      'Codice difetto': 'Difetti causati dalla presenza di acqua',
      defectType: 'defect2',
      'Descrizione difetto': '',
      sectionHeader: true,
      valorized: true,
    },
    {
      'Codice difetto': '2.1',
      'Correlazione con altri difetti': '',
      defectType: 'defect2',
      'Descrizione difetto':
        ' Insufficienza del sistema di smaltimento delle acque',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '2.2',
      'Correlazione con altri difetti': '',
      defectType: 'defect2',
      'Descrizione difetto':
        'Deterioramento del sistema di drenaggio e raccolta delle acque a tergo del rivestimento di calotta',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '2.3',
      'Correlazione con altri difetti': '',
      defectType: 'defect2',
      'Descrizione difetto':
        'Deterioramento del sistema di drenaggio e raccolta delle acque a tergo del rivestimento di calotta',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '2.4',
      'Correlazione con altri difetti': '',
      defectType: 'defect2',
      'Descrizione difetto':
        'Deterioramento del sistema di raccolta delle acque della piattaforma autostradale',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '2.5',
      'Correlazione con altri difetti': '',
      defectType: 'defect2',
      'Descrizione difetto':
        "Deterioramento delle membrane impermeabilizzanti all'estradosso del rivestimento definitivo",
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '2.6',
      'Correlazione con altri difetti': '',
      defectType: 'defect2',
      'Descrizione difetto':
        'Deterioramento degli schermi di protezione (onduline)',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '2.7',
      'Correlazione con altri difetti': '',
      defectType: 'defect2',
      'Descrizione difetto':
        "Rigonfiamento della membrana impermeabile all'intradosso",
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '2.8',
      'Correlazione con altri difetti': '',
      defectType: 'defect2',
      'Descrizione difetto':
        "Deterioramento dei rivestimenti in malta all'intradosso",
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '2.9',
      'Correlazione con altri difetti': '',
      defectType: 'defect2',
      'Descrizione difetto':
        'Deterioramento dei pannelli isolanti impermeabili',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '2.10',
      'Correlazione con altri difetti': '',
      defectType: 'defect2',
      'Descrizione difetto':
        'Deterioramento dei waterstop costituiti da cordoli idroespansivi',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': 'Eventuali note',
      defectType: 'defect2',
      'Descrizione difetto': '',
      'Estensione K1': null,
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      valorized: true,
    },
  ],
  // INFO: Defect 3
  defect3: [
    {
      'Codice difetto':
        'Difetti realtivi agli elementi strutturali e alla geometria della galleria - fessure',
      defectType: 'defect3',
      'Descrizione difetto': '',
      sectionHeader: true,
      valorized: true,
    },
    {
      'Codice difetto': '3.1',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto':
        'Presenza di fessurazioni longitudinali lungo il rivestimento',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '3.2',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto': 'Fessure diagonali',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '3.3',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto': 'Fessure verticali',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '3.4',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto': 'Fessure da ritiro',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '3.5',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto': 'Fessure curvilinee',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto':
        'Difetti relativi agli strumenti e alla geometria della galleria - deformazioni',
      defectType: 'defect3',
      'Descrizione difetto': '',
      sectionHeader: true,
      valorized: true,
    },
    {
      'Codice difetto': '3.6',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto':
        'Abbassamento in chiave deformazione simmetrica deformazione asimmetrica',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '3.7',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto': 'Imbozzamento localizzato',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '3.8',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto': 'Disassamento dei conci murari',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '3.9',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto': "Deterioramento dell'arco rovescio",
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '3.10',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto': "Rottura dell'arco (reni, calotta)",
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto':
        'Difetti relativi agli strumenti e alla geometria della galleria - difetti costruttivi',
      defectType: 'defect3',
      'Descrizione difetto': '',
      sectionHeader: true,
      valorized: true,
    },
    {
      'Codice difetto': '3.11',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto':
        'Scavo localizzato per cariche esplosive instabile',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '3.12',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto':
        "Presenza di vuoti in prossimità dell'intradosso del rivestimento",
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '3.13',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto': 'Sgretolamenti - vespa',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '3.14',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto': 'Deterioramento dei giunti in calcestruzzo',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '3.15',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto': 'Difetti della superficie nel calcestruzzo',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': 'Difetti associati al fuoco',
      defectType: 'defect3',
      'Descrizione difetto': '',
      sectionHeader: true,
      valorized: true,
    },
    {
      'Codice difetto': '3.16',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto': 'Danneggiamenti dovuti ad incendio',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': 'Difetti associati alla scarsa manutenzione',
      defectType: 'defect3',
      'Descrizione difetto': '',
      sectionHeader: true,
      valorized: true,
    },
    {
      'Codice difetto': '3.17',
      'Correlazione con altri difetti': '',
      defectType: 'defect3',
      'Descrizione difetto':
        'Scarsa manutenzione, ripristini ammalorati, canalette ammalorate o inefficienti',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': 'Eventuali note',
      defectType: 'defect3',
      'Descrizione difetto': '',
      'Estensione K1': null,
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      valorized: true,
    },
  ],
  // INFO: Defect 4
  defect4: [
    {
      'Codice difetto': '4.1',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Ormaie',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.2',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Ondulazioni trasversali',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.3',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Avvallamenti di vaste superfici',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.4',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Depressioni localizzate',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.5',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Fessurazioni longitudinali',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.6',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Fessurazioni ramificate',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.7',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Presenza di buche',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.8',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Risalita sul piano viabile di materiale',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.9',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto':
        'Dissesti ai giunti delle sovrastrutture in calcestruzzo',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.10',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Dissesti della lastra in calcestruzzo',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.11',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': "Riduzione dell'aderenza",
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.12',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Dissesti della banchina destra',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.13',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Dissesti della banchina sinistra',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.14',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Dissesti del marciapiede destro',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.15',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Dissesti del marciapiede sinistro',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.16',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Disallineamento segnaletica orizzontale',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '4.17',
      'Correlazione con altri difetti': '',
      defectType: 'defect4',
      'Descrizione difetto': 'Disallineamento segnaletica verticale',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': 'Eventuali note',
      defectType: 'defect4',
      'Descrizione difetto': '',
      'Estensione K1': null,
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      valorized: true,
    },
  ],
  // INFO: Defect 5
  defect5: [
    {
      'Codice difetto': '5.1',
      'Correlazione con altri difetti': '',
      defectType: 'defect5',
      'Descrizione difetto': 'Deterioramento di lastre e tamponature',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': '5.2',
      'Correlazione con altri difetti': '',
      defectType: 'defect5',
      'Descrizione difetto': 'Instabilità del sistema di ancoraggio/supporto',
      'Estensione K1': null,
      G: '',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      'PS g': '',
      'PS I': '',
      'Sup. Est. Dif. [m^2]': '',
      valorized: false,
    },
    {
      'Codice difetto': 'Eventuali note',
      defectType: 'defect5',
      'Descrizione difetto': '',
      'Estensione K1': null,
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      valorized: true,
    },
  ],
};
