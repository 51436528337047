import { OverlayContainer } from '@angular/cdk/overlay';
import {
  Component,
  OnInit,
  OnDestroy,
  Renderer2,
  HostListener,
} from '@angular/core';

import { CubeService } from './services/cube/cube.service';
import { IdbService } from './services/storage/idb.service';
import { LanguageService } from './services/language/language.service';
import { MigrationService } from './services/migration/migration.service';
import { STORAGE_KEYS } from './services/storage/storage.service';
import { SyncService } from './services/sync/sync.service';
import { ThemeService } from './services/theme/theme.service';

import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'cube-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isDarkTheme: boolean;
  private subscriptions: Subscription[] = [];

  constructor(
    translate: TranslateService,
    private overlayContainer: OverlayContainer,
    private themeService: ThemeService,
    private cubeService: CubeService,
    private idbService: IdbService,
    private languageService: LanguageService,
    private migrationService: MigrationService,
    private primeNgConfig: PrimeNGConfig,
    private renderer: Renderer2,
    private syncService: SyncService
  ) {
    this.languageService.setDefaultLanguage();
  }

  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.themeService.isDarkTheme.subscribe((isDark) => {
        this.isDarkTheme = isDark;
        this.overlayContainer
          .getContainerElement()
          .classList.remove(isDark ? 'light-theme' : 'dark-theme');
        this.overlayContainer
          .getContainerElement()
          .classList.add(!isDark ? 'light-theme' : 'dark-theme');
      })
    );

    fromEvent(window, 'storage')
      .pipe(filter((ev: StorageEvent) => ev.key === STORAGE_KEYS.currentTenant))
      .subscribe((storageEvent: StorageEvent) => {
        this.cubeService.cleanCache();
        window.location.reload();
      });

    this.primeNgConfig.ripple = true;

    this.renderer.setStyle(document.body, 'overscroll-behavior-y', 'contain');
    this.renderer.setStyle(document.body, 'overscroll-behavior-x', 'contain');

    // Setup indexedDB
    this.idbService.initializeDatabase();
    this.migrationService.migrateIdbMasterTables(); // TEMP: For migration
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  @HostListener('window:beforeunload')
  onWindowUnload(): void {
    this.syncService.onPageReload();
  }
}
