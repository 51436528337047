<!-- Error msg for single file upload file exceeding size limit -->
<p *ngIf="errorCode === fileUploadError.FileSizeExceeded">
  {{
    "file_upload_error_message.file_upload_size_exceeded"
      | translate: { sizeLimit: formattedSizeLimit }
  }}
</p>

<!-- Error msg for single file upload where image exceeding dimension limit -->
<p *ngIf="errorCode === fileUploadError.ImageDimensionsExceeded">
  {{
    "file_upload_error_message.image_dimension_exceeded"
      | translate: { maxImageDimension: maxImageWidthHeight }
  }}
</p>

<!-- Error msg for single file upload where unable to get image dimensions -->
<p *ngIf="errorCode === fileUploadError.UnableToGetImageDimensions">
  {{ "file_upload_error_message.unable_to_get_image_dimensions" | translate }}
</p>

<!-- Error msg for multiple file upload where files exceeding size limit -->
<p *ngIf="sizeExceededFilesList?.length">
  {{
    "file_upload_error_message.file_upload_size_exceeded_multiple"
      | translate
        : {
            files: sizeExceededFilesList.join(", "),
            sizeLimit: formattedSizeLimit
          }
  }}
</p>

<!-- Error msg for multiple file upload where images exceeding dimension limit -->
<p *ngIf="dimensionExceededImageList?.length">
  {{
    "file_upload_error_message.image_dimension_exceeded_multiple"
      | translate
        : {
            images: dimensionExceededImageList.join(", "),
            maxImageDimension: maxImageWidthHeight
          }
  }}
</p>

<!-- Error msg for multiple file upload where unable to get image dimensions -->
<p *ngIf="unableToGetImageDimensionsFilesList?.length">
  {{
    "file_upload_error_message.unable_to_get_image_dimensions"
      | translate: { files: unableToGetImageDimensionsFilesList.join(", ") }
  }}
</p>
