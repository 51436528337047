import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { environment } from '../../../environments/environment';
import { RequestMetadata, RequestOptions } from '../../models/api';
import { HANDLED_ERRORS } from '../../shared/utils/http-context';

import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  protected endpoint: any;
  private defaultRequestMetadata: RequestMetadata = {
    context: new HttpContext().set(HANDLED_ERRORS, []),
  };

  constructor(
    protected httpClient: HttpClient,
    protected logger: NGXLogger,
    protected snackBar: MatSnackBar,
    protected translateService: TranslateService
  ) {}

  protected debugLog(operation: string): void {
    // console.log(operation);
  }

  protected delete<T>({
    body = null,
    errorResult,
    metadata = {},
    operation,
    url,
  }: RequestOptions): Observable<T> {
    return this.httpClient
      .delete<T>(url, {
        ...this.defaultRequestMetadata,
        ...metadata,
        body,
      })
      .pipe(
        tap(() => {
          this.debugLog(operation);
        }),
        catchError(this.handleError<typeof errorResult>(errorResult, metadata))
      );
  }

  protected get<T>({
    errorResult,
    metadata = {},
    operation,
    url,
  }: Omit<RequestOptions, 'body'>): Observable<T> {
    return this.httpClient
      .get<T>(url, {
        ...this.defaultRequestMetadata,
        ...metadata,
      })
      .pipe(
        tap(() => {
          this.debugLog(operation);
        }),
        catchError(this.handleError<typeof errorResult>(errorResult, metadata))
      );
  }

  protected getHttpParams(
    params: Record<any, any>,
    isFiltered: boolean = true
  ): { params: HttpParams } {
    if (!isFiltered) {
      return {
        params: new HttpParams({
          fromObject: params,
        }),
      };
    }

    const filteredParams = Object.entries(params).reduce(
      (filteredParamsObj, [paramKey, paramValue]) => {
        if (paramValue != null) {
          filteredParamsObj[paramKey] = paramValue;
        }

        return filteredParamsObj;
      },
      {} as Record<string, any>
    );

    return {
      params: new HttpParams({
        fromObject: filteredParams,
      }),
    };
  }

  protected post<T>({
    body = null,
    errorResult,
    metadata = {},
    operation,
    url,
  }: RequestOptions): Observable<T> {
    return this.httpClient
      .post<T>(url, body, {
        ...this.defaultRequestMetadata,
        ...metadata,
      })
      .pipe(
        tap(() => {
          this.debugLog(operation);
        }),
        catchError(this.handleError<typeof errorResult>(errorResult, metadata))
      );
  }

  protected put<T>({
    body = null,
    errorResult,
    metadata = {},
    operation,
    url,
  }: RequestOptions): Observable<T> {
    return this.httpClient
      .put<T>(url, body, {
        ...this.defaultRequestMetadata,
        ...metadata,
      })
      .pipe(
        tap(() => {
          this.debugLog(operation);
        }),
        catchError(this.handleError<typeof errorResult>(errorResult, metadata))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(result?: T, metadata?: any) {
    return (err: any): Observable<T> => {
      const handledErrors = metadata?.context?.get(HANDLED_ERRORS);
      const isErrorHandled =
        handledErrors != null
          ? handledErrors.length === 0 || // Length is 0 if all errors are handled
            handledErrors.includes(err.status) // List will contain status codes of errors if they are handled
          : false;
      const errorMessage = err.error?.error ?? err; // Get error message from error object. If not found, use error object

      // Show message if error is not handled in component
      if (!isErrorHandled) this.showMessage(errorMessage);

      return result != null
        ? of(result) // Let the app keep running by returning the result
        : throwError(errorMessage); // Rethrow error back to service
    };
  }

  private showMessage(msg: string): void {
    const snackBarConfig = new MatSnackBarConfig();
    snackBarConfig.duration = environment.snackbar_error_duration;
    snackBarConfig.panelClass = ['snackBarMessage', 'errorMessage'];

    this.snackBar.open(
      this.translateService.instant(msg),
      'close',
      snackBarConfig
    );
  }
}
