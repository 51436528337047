<!-- Close Button -->
<button
  class="close-icon"
  tabindex="-1"
  type="button"
  mat-dialog-close
  mat-icon-button
>
  <mat-icon>close</mat-icon>
</button>

<!-- Content -->
<mat-dialog-content>
  <div class="dialog-form">
    <p>
      <span>
        {{ "sync_failed_dialog.sync_failed" | translate }}
      </span>
    </p>

    <div class="sync-details">
      <!-- Codice IOP -->
      <p>
        <span>{{ "sync_failed_dialog.codice_iop" | translate }}: </span>
        {{ codiceIop }}
      </p>

      <!-- Nome Ponte/Viadotto -->
      <p>
        <span>{{ "sync_failed_dialog.nome_ponte" | translate }}: </span>
        {{ nomePonte }}
      </p>

      <!-- Failed Reason -->
      <p>
        <span>{{ "sync_failed_dialog.failed_reason" | translate }}: </span>
        {{ failedReason | translate }}
      </p>

      <!-- Synced Files -->
      <div *ngIf="completedFiles">
        <!-- Count -->
        <p>
          <span>{{ "sync_failed_dialog.synced_files" | translate }}: </span>
          {{ completedFiles }} / {{ totalFiles }}
        </p>

        <br />

        <!-- Note -->
        <p>
          <span class="sync-note">{{
            "sync_failed_dialog.sync_failed_note" | translate
          }}</span>
        </p>
      </div>
    </div>
  </div>
</mat-dialog-content>
