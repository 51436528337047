import { environment } from '../../../environments/environment';

const localJSON = './assets/json';
/*
 ****** The index is sorted by alphabetical order for scan it faster,
 ****** please when you add a voice take a minute to add it in the right spot
 ****** keep the order!
 */
export const API = {
  ADMIN_TOOL: {
    roleFeatures: (role: string) =>
      `${environment.ADMIN_TOOL_URL}/tenants/current/roles/${role}/platforms/CUBE/solutions/${environment.SOLUTION}/features`,

    tenants: (username: string) =>
      `${environment.ADMIN_TOOL_URL}/users/${username}/solutions/${environment.SOLUTION}/tenants`,

    userProfile: (username: string) =>
      `${environment.ADMIN_TOOL_URL}/tenants/current/users/${username}/profile`,
  },
  DYNAMIC_FORM: {
    getTemplate: (templateId: string) =>
      `${environment.REST}/DynamicFormAPI/GetTemplate/${templateId}`,

    getTemplates: () => `${environment.REST}/DynamicFormAPI/GetTemplates`,
  },
  INSPECTION: {
    attentionClassCalculation: () =>
      `${environment.REST}/InspectionAPI/AttentionClassCalculation`,

    autocomplete: () => `${environment.REST}/InspectionAPI/Autocomplete`,

    defectsListBridge: () =>
      `${environment.REST}/InspectionAPI/DefectsListBridge`,

    defectsListTunnel: () =>
      `${environment.REST}/InspectionAPI/DefectsListTunnel`,

    deleteInspectionMedia: () =>
      `${environment.REST}/InspectionAPI/DeleteInspectionMedia`,

    getInspection: (inspectionId: string) =>
      `${environment.REST}/InspectionAPI/GetInspection/${inspectionId}`,

    getInspections: () => `${environment.REST}/InspectionAPI/GetInspections`,

    inspectionMediaUpload: (id: string) =>
      `${environment.REST}/InspectionAPI/InspectionMediaUpload/${id}`,

    isOnline: () => `${environment.REST}/InspectionAPI/IsOnline`,

    takeInChargeInspection: () =>
      `${environment.REST}/InspectionAPI/TakeInchargeInspection`,

    updateInspectionStatus: (inspectionId: string) =>
      `${environment.REST}/InspectionAPI/UpdateInspectionStatus/${inspectionId}`,
  },
  INSPECTION_CRUD: {
    createInspection: () =>
      `${environment.REST}/InspectionAPICRUD/CreateInspection`,

    getPlannedInspection: (inspectionId: string) =>
      `${environment.REST}/InspectionAPICRUD/GetPlannedInspection/${inspectionId}`,

    getPlannedInspections: () =>
      `${environment.REST}/InspectionAPICRUD/GetPlannedInspections`,

    updateInspection: (inspectionId: string) =>
      `${environment.REST}/InspectionAPICRUD/UpdateInspection/${inspectionId}`,
  },
  PROFILE: {
    createUser: () => `${environment.REST}/SignatureProfileAPICRUD/CreateUser`,

    getUser: () => `${environment.REST}/SignatureProfileAPICRUD/GetUser`,

    updateUser: (userId: string) =>
      `${environment.REST}/SignatureProfileAPICRUD/UpdateUser/${userId}`,

    uploadSignature: (userId: string) =>
      `${environment.REST}/SignatureProfileAPICRUD/UploadSignature/${userId}`,
  },
  TENANT: {
    getEnabledTenants: () => `${environment.REST}/TenantAPI/GetEnabledTenants`,

    getTenant: (tenantId: string) =>
      `${environment.REST}/TenantAPI/GetTenant/${tenantId}`,

    getTenants: () => `${environment.REST}/TenantAPI/GetTenants`,
  },
};
