import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Response } from '../../../models/api';
import { UserCreate, UserDetails } from '../../../models/profile';
import { HANDLED_ERRORS } from '../../../shared/utils/http-context';
import { API } from '../api.const';
import { ApiService } from '../api.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends ApiService {
  endpoint = API.PROFILE;

  createUser(body: UserCreate): Observable<Response<UserDetails>> {
    return this.post<Response<UserDetails>>({
      body,
      operation: 'createUser',
      url: this.endpoint.createUser(),
    });
  }

  getUserDetails(email: string): Observable<Response<UserDetails>> {
    return this.get<Response<UserDetails>>({
      metadata: {
        ...this.getHttpParams({
          email,
        }),
      },
      operation: 'getUserDetails',
      url: this.endpoint.getUser(),
    });
  }

  updateUserDetails(
    formData: any,
    userId: string
  ): Observable<Response<UserDetails>> {
    return this.put<Response<UserDetails>>({
      body: formData,
      operation: 'updateUserDetails',
      url: this.endpoint.updateUser(userId),
    });
  }

  uploadUserSignature(
    formData: any,
    userId: string
  ): Observable<Response<UserDetails>> {
    return this.post<Response<UserDetails>>({
      body: formData,
      operation: 'uploadUserSignature',
      url: this.endpoint.uploadSignature(userId),
    });
  }
}
