import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[cubeDisableAfterClick]',
})
export class DisableAfterClickDirective implements OnChanges, OnDestroy {
  @Input('cubeDisableAfterClick') externalDisableConditions?: boolean = true;
  @Input() reEnableButton?: EventEmitter<boolean>;

  @HostBinding('disabled')
  @HostBinding('class.mat-button-disabled')
  isDisabled: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();
  private isClicked: boolean = false;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { externalDisableConditions, reEnableButton } = changes;

    // External disable conditions
    if (
      externalDisableConditions?.previousValue !==
      externalDisableConditions?.currentValue
    ) {
      this.setIsDisabled();
    }

    // re-enable button
    if (reEnableButton?.previousValue !== reEnableButton?.currentValue) {
      this.setEventListeners();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('click')
  onClick(): void {
    this.isClicked = true;
    this.setIsDisabled();

    // Manually submitting form if button type is submit
    const { nativeElement: buttonEl } = this.elementRef;
    if (buttonEl.type === 'submit') {
      buttonEl.form.dispatchEvent(new Event('ngSubmit'));
    }
  }

  private setEventListeners(): void {
    this.reEnableButton?.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isClicked = false;
      this.setIsDisabled();
    });
  }

  private setIsDisabled(): void {
    this.isDisabled = this.isClicked || this.externalDisableConditions;
  }
}
