import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly _storage = localStorage;

  addDeletedFileUrlsToStorage(deletedFileUrlsList: any[]): void {
    const deletedFilesList =
      this.getFromStorage(STORAGE_KEYS.deletedFiles) ?? [];
    this.addToStorage(
      STORAGE_KEYS.deletedFiles,
      deletedFilesList.concat(deletedFileUrlsList)
    );
  }

  addSavedFileUrlsToStorage(newFileUrlsList: string[]): void {
    const savedFilesList = this.getFromStorage(STORAGE_KEYS.savedImages) ?? [];
    this.addToStorage(
      STORAGE_KEYS.savedImages,
      savedFilesList.concat(newFileUrlsList)
    );
  }

  addToStorage(key: string, value: any): void {
    this._storage.setItem(key, JSON.stringify(value));
  }

  clearFilesListInStorage(key: string): void {
    this.addToStorage(key, []);
  }

  deleteFromStorage(key: string): any {
    this._storage.removeItem(key);
  }

  getDeletedFilesListFromStorage(): any[] {
    return this.getFromStorage(STORAGE_KEYS.deletedFiles) ?? [];
  }

  getSavedFilesListFromStorage(): any[] {
    return this.getFromStorage(STORAGE_KEYS.savedImages) ?? [];
  }

  getFromStorage(key: string): any {
    return JSON.parse(this._storage.getItem(key));
  }
}

export const STORAGE_KEYS = {
  activeRole: 'active-role',
  currentSimulations: 'current-simulations',
  currentTenant: 'current-tenant',
  currentWTGs: 'current-wtgs',
  darkTheme: 'is-dark-theme',
  deletedFiles: 'deletedFiles',
  excludedMap: 'excluded-map',
  invisibleWTGs: 'invisible-wtgs',
  language: 'language',
  localTimeFormat: 'local_time_format',
  mapBaseLayer: 'map-base-layer',
  rolesAndModules: 'rolesAndModules',
  savedImages: 'savedImages',
  sub: 'sub',
  token: 'token',
  user: 'user',
  version: 'version',
};
